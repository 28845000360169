<template>
  <div style="">
    <v-form @submit.prevent="submit">
      <v-row>
        <v-col cols="6">
          <v-select
            v-model="form.educational_level_id"
            label="Nível de ensino"
            required
            :items="levels"
            item-text="name"
            item-value="id"
          ></v-select>
          <!-- //@change="listInstituitionsByLevel" -->
        </v-col>

        <v-col cols="6">
          <v-text-field
            v-model="form.educational_instituition_name"
            label="Instituição de Ensino"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field
            v-model="form.grade"
            label="Período/Série"
          ></v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field v-model="form.course" label="Curso"></v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field v-model="form.register" label="RA"></v-text-field>
        </v-col>
      </v-row>

      <v-btn color="primary" class="mr-4" type="submit" :disabled="loading"
        >Avançar</v-btn
      >
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'EducationalComponent',

  data() {
    return {
      form: {
        educational_instituition_name: '',
        educational_level_id: '',
        grade: '',
        course: '',
        register: '',
      },
      loading: false,
      levels: [],
      instituitions: [],
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  mounted() {
    this.listEducationalLevels();
    console.log('Dados do estudante', this.$store.state.user);
    setTimeout(() => {
      this.$set(
        this.form,
        'educational_instituition_name',
        this.user?.educational_instituition_name
      );
      this.$set(
        this.form,
        'educational_level_id',
        this.user?.educational_level_id
      );
      this.$set(this.form, 'grade', this.user?.grade);
      this.$set(this.form, 'course', this.user?.course);
      this.$set(this.form, 'register', this.user?.register);
    }, 500);
  },
  methods: {
    async listEducationalLevels() {
      try {
        const response = await this.$http.get('/educational-levels', {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('tk')}`,
          },
        });
        this.levels = response.data;
      } catch (e) {
        console.error(e);
      }
    },
    async listInstituitionsByLevel() {
      try {
        const response = await this.$http.get(
          `/educational-instituitions/by-level/${this.form.educational_level_id}`,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem('tk')}`,
            },
          }
        );
        this.instituitions = response.data;
      } catch (e) {
        console.error(e);
      }
    },
    async submit() {
      this.loading = true;
      console.log('enviando form pro server', this.form);

      try {
        const response = await this.$http.put(
          `/estudents/${this.$store.state.user.id}/educational`,
          this.form,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem('tk')}`,
            },
          }
        );
        this.loading = false;
        if (
          response.status === 200 &&
          response.data.message == 'Dados educacionais atualizados com sucesso'
        ) {
          this.$store.commit('setUser', {
            ...this.$store.state.user,
            ...response.data.estudent,
          });
          this.$router.push({ name: 'Documentos' });
        }
      } catch (e) {
        this.loading = false;
        console.error(e);
      }
    },
  },
};
</script>

<style></style>
