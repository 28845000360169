/* eslint-disable no-useless-escape */
import Vue from "vue";
import App from "./App.vue";
import "es6-promise/auto";
import "core-js/es/array";
import VueRouter from "vue-router";
import axios from "axios";
import Notifications from "vue-notification";
import DatetimePicker from "vuetify-datetime-picker";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/src/locale/pt.ts";
import router from "./router";
import store from "./store";
import VueSplide from "@splidejs/vue-splide";
import "@mdi/font/css/materialdesignicons.css";
import VueTheMask from "vue-the-mask";
import * as debounce from "lodash.debounce";
// import 'material-design-icons-iconfont/dist/material-design-icons.css'
// import '@splidejs/splide/dist/css/themes/splide-default.min.css';

axios.defaults.baseURL = `https://novo.app.meiaentradadigital.com.br/api`;
axios.defaults.headers.common = {
  Accept: "application/json",
  // Authorization: `Bearer ${localStorage.getItem('tk')}`,
  // clientId: 'clientIDTeste',
  // clientSecret: 'clientSecret',
};
// dio.options.headers['accept'] = 'application/json';
// dio.options.headers['Content-Type'] = 'application/json';

Vue.prototype.$http = axios;
Vue.router = router;
Vue.use(VueRouter);
Vue.use(Notifications);
Vue.use(DatetimePicker);
Vue.use(VueSplide);
Vue.use(VueTheMask);

Vue.prototype.$formatDate = (dateStr, withHours, defaultValue) =>
  dateStr
    ? Intl.DateTimeFormat("pt-BR", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        ...(withHours
          ? {
              hour: "2-digit",
              minute: "2-digit",
            }
          : {}),
      }).format(new Date(dateStr))
    : defaultValue ?? "N/A";

let _debounces = {};
Vue.prototype.$debounce = (k, f, d) => {
  if (_debounces[k]) _debounces[k]?.cancel();
  _debounces[k] = debounce(() => {
    f();
    delete _debounces[k];
  }, d ?? 650);
  _debounces[k]();
};

Vue.component("dialog-pix", () => import("./components/DialogPixForm.vue"));

export default new Vuetify({
  lang: {
    locales: { pt },
    current: "pt",
  },
});

Vue.use(Vuetify);
Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  store,
  router,
  vuetify: new Vuetify(),
}).$mount("#app");
