<template>
  <div
    :style="`background-image: url(./../../imgs/home/bg_section_1.jpg)`"
    class="content__section_1"
  >
    <v-container class="pa-0">
      <HeaderComponent />
      <div class="bg_section">
        <h3 class="txt_contato">Contato</h3>
        <div class="linha_caminho">
          <router-link to="/" class="link inicial">
            <p class="inicial">Página inicial</p>
          </router-link>
          <p class="inicial">&nbsp;&nbsp;>>&nbsp;&nbsp;</p>
          <p class="pagina">CONTATO</p>
        </div>
        <div class="bg_section">
          <div class="section">
            <div class="txt_section">
              <p class="paragrafo">
                Tem uma sugestão ou quer ser tornar Licenciado ou Parceiro?
              </p>
              <p class="paragrafo">
                Envie uma mensagem que retornaremos assim que possível.
              </p>

              <div class="form">
                <v-form>
                  <v-row>
                    <v-col cols="12" md="11">
                      <v-text-field
                        v-model="name"
                        label="Nome da pessoa"
                        :rules="[(v) => !!v || 'Campo Obrigatório']"
                        dark
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="11">
                      <v-text-field
                        label="E-mail"
                        v-model="email"
                        dark
                        :rules="[
                          (v) => !!v || 'Campo obrigatório',
                          (v) => isValidEmail(v) || 'E-mail Inválido!',
                        ]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="11">
                      <v-text-field
                        v-model="phone"
                        dark
                        v-mask="'(##) #####-####'"
                        label="Telefone"
                        :rules="[(v) => !!v || 'Campo Obrigatório']"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="11">
                      <v-textarea
                        v-model="body"
                        dark
                        outlined
                        label="Mensagem"
                        :rules="[(v) => !!v || 'Campo Obrigatório']"
                        required
                      ></v-textarea>
                    </v-col>
                  </v-row>

                  <v-btn
                    class="btn_enviar"
                    type="submit"
                    :loading="loading"
                    @click.prevent="emailContato()"
                  >
                    <span class="centrarlizar_btn">
                      <p class="enviar">Enviar</p>
                    </span>
                  </v-btn>
                </v-form>
              </div>
            </div>
            <div class="atendimento_section">
              <h4 class="titulo_atendimento">Central de atendimento</h4>
              <div class="linha_contato">
                <img
                  src="./../../public/imgs/icones/call2.png"
                  alt="Icone de um telefone"
                />
                <p class="infos">(11) 93908-1255</p>
              </div>
              <div class="linha_contato">
                <img
                  src="./../../public/imgs/icones/mail2.png"
                  alt="Icone de uma caixa de e-mail"
                />
                <a href="mailto:contato@meiaentradadigital.com.br" class="infos">contato@meiaentradadigital.com.br</a>
              </div>
              <h4 class="titulo_atendimento">Baixe o app</h4>
              <v-flex class="btn_app">
                <a
                  href="https://itunes.apple.com/br/app/id1670378560?l=pt&mt=8"
                  class="link"
                  target="_blank"
                >
                  <img
                    src="./../../public/imgs/btns/btn_apple.png"
                    alt="botão da loja de aplicativos da apple "
                    style="width: 150px"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=br.com.meiaentradadigital&hl=pt_BR"
                  class="link"
                  target="_blank"
                >
                  <img
                    src="./../../public/imgs/btns/btn_google_play.png"
                    alt="botão da loja de aplicativos da google play"
                    style="width: 150px"
                  />
                </a>
              </v-flex>
            </div>
          </div>
        </div>
      </div>
    </v-container>
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent";
import FooterComponent from "@/components/FooterComponent.vue";
/* eslint-disable */
import * as EmailValidator from "email-validator";

export default {
  name: "ContatoPage",
  props: {},
  components: {
    HeaderComponent,
    FooterComponent,
    EmailValidator,
  },
  data() {
    return {
      email: null,
      name: null,
      phone: null,
      body: null,
      loading: false,
    };
  },
  computed: {},
  methods: {
    isValidEmail(email) {
      return EmailValidator.validate(email);
    },
    async emailContato() {
      //validação de campos
      if (!this.name || !this.phone || !this.body) {
        if (this.email) {
          if (!this.isValidEmail(this.email)) {
            return;
          }
        }
        alert(
          "Preencha os campos do formulario. Eles são obrigatórios para seu contato ser enviado. "
        );
        return;
      }

      this.loading = true;
      var params = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        body: this.body,
      };
      await this.$http
        .post("/contact", params)
        .then((r) => {
          this.loading = false;
          console.log(r);
          console.log(r?.data);

          this.$notify({
            group: "app",
            type: "success",
            title: "Seu contato foi enviado",
            text: "Com sucesso!",
          });
        })
        .catch((e) => {
          console.log(e.response || e);
          this.loading = false;
          this.$notify({
            group: "app",
            type: "error",
            title: "Ocorreu um erro",
            text: e.response?.data?.message || "Tente novamente mais tarde",
          });
        });
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.content__section_1 {
  background-size: auto 100%;
  background-position: right center;
}

.bg_section {
  border-radius: 1rem;
  position: relative;
  z-index: 1;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  padding: 2rem;
  backdrop-filter: blur(15px);
}
.txt_contato {
  margin-top: 25px;
  font-size: 69px;
  line-height: 84px;
  color: white;
  font-weight: 700;
  font-family: yummo, sans-serif;
}
.inicial {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: white;
}
.pagina {
  font-size: 14px;
  line-height: 17px;
  color: white;
}
.section {
  display: flex;
  justify-content: space-between;
}
.paragrafo {
  font-family: "Calibri", "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: white;
}
.btn_enviar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  margin: 25px 0;
  gap: 8px;
  width: 510px;
  height: 46px;
  background: linear-gradient(90deg, #ff8008 0%, #ffc837 100%);
  border: 2px solid #fa9500;
  border-radius: 300px;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.enviar {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #1c4173;
}
.txt_section {
  width: 550px;
}
.atendimento_section {
  width: 550px;
}
.titulo_atendimento {
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: white;
}
.infos {
  font-weight: 700;
  font-size: 1rem;
  line-height: 28px;
  color: white;
}
.linha_caminho {
  display: flex;
}
@media screen and (orientation: portrait), (max-width: 1000px) {
  .bg_fantasma {
    height: 1200px;
  }
  .linha_caminho {
    text-align: start;
  }
  .txt_section,
  .btn_enviar {
    width: 80vw;
  }
  .section {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .atendimento_section {
    width: 80vw;
    text-align: center;
  }
  .linha_contato {
    width: 100%;
    justify-content: center;
    text-align: center;
  }
}
</style>
