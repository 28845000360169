var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{ref:"studentcardcontainer",staticClass:"ma-0"},[_c('v-col',{staticClass:"ma-0 pa-0"},[(_vm.loading == true)?_c('v-progress-circular',{attrs:{"indeterminate":""}}):_vm._e(),_c('v-responsive',{attrs:{"aspect-ratio":_vm.currentAspectRatio}},[_c('div',{staticClass:"container__card"},[(_vm.user)?_c('div',{staticClass:"card__general"},[(_vm.showFront)?_c('div',{staticClass:"card__front"},[(_vm.user?.card_active?.status != 2)?_c('div',{staticClass:"flag_status"},[_c('h4',{staticClass:"status_card"},[_vm._v(" "+_vm._s(_vm.user?.card_active?.status_label)+" ")])]):_vm._e(),_c('div',{style:({
                position: 'absolute',
                // backgroundColor: 'red',
                backgroundImage: `url(${_vm.user?.files[0].url || ''})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                ..._vm.getPositions('picture'),
              })}),(_vm.user?.card_active?.status == 2)?_c('div',{style:({
                position: 'absolute',
                // backgroundColor: 'red',
                backgroundImage: `url(${_vm.user?.files[5]?.url ?? ''})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                ..._vm.getPositions('qrcode'),
              })}):_vm._e(),_c('h1',{staticClass:"txt__card",style:({
                fontSize: _vm.getPercentageOfHeight(3.7),
                ..._vm.getPositions('name'),
              })},[_vm._v(" "+_vm._s(_vm.user?.name)+" ")]),_c('h1',{staticClass:"txt__card",style:({
                fontSize: _vm.getPercentageOfHeight(3.7),
                ..._vm.getPositions('document'),
              })},[_vm._v(" "+_vm._s(_vm.user?.document)+" ")]),_c('h1',{staticClass:"txt__card",style:({
                fontSize: _vm.getPercentageOfHeight(3.7),
                ..._vm.getPositions('real_id'),
              })},[_vm._v(" "+_vm._s(_vm.user?.real_id || "")+" ")]),_c('h1',{staticClass:"txt__card",style:({
                fontSize: _vm.getPercentageOfHeight(3.7),
                ..._vm.getPositions('birth_date'),
              })},[_vm._v(" "+_vm._s(_vm.$formatDate(_vm.user?.birth_date, false, ""))+" ")]),_c('h1',{staticClass:"txt__card",style:({
                fontSize: _vm.getPercentageOfHeight(3.7),
                ..._vm.getPositions('course'),
              })},[_vm._v(" "+_vm._s(_vm.user?.course || "")+" ")]),_c('h1',{staticClass:"txt__card",style:({
                fontSize: _vm.getPercentageOfHeight(3.7),
                ..._vm.getPositions('educational_instituition_name'),
              })},[_vm._v(" "+_vm._s(_vm.user?.educational_instituition_name ?? "")+" ")]),_c('h1',{staticClass:"txt__card",style:({
                fontSize: _vm.getPercentageOfHeight(3.7),
                ..._vm.getPositions('educational_level_name'),
              })},[_vm._v(" "+_vm._s(_vm.user?.educational_level_name ?? "")+" ")]),_c('h1',{staticClass:"txt__card register",style:({
                fontSize: _vm.getPercentageOfHeight(3.7),
                ..._vm.getPositions('register'),
              })},[_vm._v(" "+_vm._s(_vm.user?.register ?? "")+" ")]),(_vm.user?.card_active?.status == 2)?_c('h1',{staticClass:"txt__card no__uppercase",style:({
                fontSize: _vm.getPercentageOfHeight(3.7),
                ..._vm.getPositions('code'),
              })},[_vm._v(" "+_vm._s(_vm.user?.code ?? "")+" ")]):_vm._e(),_c('img',{staticClass:"img_card",attrs:{"width":"100%","height":"100%","src":_vm.cardFront,"alt":"carteirinha do estudantes com elementos em volta como pipoca oculos em 3d guitarra e um gol com uma bola de futebol"}})]):_vm._e(),(!_vm.showFront)?_c('div',{staticClass:"card__back"},[_c('img',{staticClass:"img_card",attrs:{"width":"100%","height":"100%","src":_vm.cardVerse,"alt":"carteirinha do estudantes com elementos em volta como pipoca oculos em 3d guitarra e um gol com uma bola de futebol"}})]):_vm._e()]):_c('div',[_vm._v("Carteirinha indisponível no momento")])])]),(_vm.user?.card_active?.status == 2)?_c('v-dialog',{attrs:{"width":_vm.studentCardWidth,"scrollable":false,"fullscreen":true},scopedSlots:_vm._u([{key:"activator",fn:function({ on: dialog }){return [_c('v-row',{style:({
            margin: 'unset',
            marginTop: _vm.getPercentageOfHeight(3),
          })},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-btn',_vm._g({staticClass:"btn btn__action pa-0 ma-0 mx-auto",style:({
                fontSize: _vm.getPercentageOfHeight(3.7),
              }),attrs:{"block":""}},{ ...dialog }),[_vm._v(" Ver QrCode ")])],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"btn btn__action pa-0 ma-0 mx-auto",style:({
                fontSize: _vm.getPercentageOfHeight(3.7),
              }),attrs:{"block":""},on:{"click":function($event){_vm.showFront = !_vm.showFront}}},[_vm._v(" Ver "+_vm._s(_vm.showFront ? "Verso" : "Frente")+" ")])],1)],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',{attrs:{"width":_vm.studentCardWidth,"height":_vm.studentCardHeight}},[_c('v-card-text',{staticClass:"pb-0",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"content__qrcode"},[_c('div',{staticClass:"img_qrcode_full",style:(`background-image: url(${_vm.user?.files[5].url});`)}),_c('v-btn',{staticClass:"btn centrarlizar_btn btn_sair",staticStyle:{"max-height":"20%"},attrs:{"block":""},on:{"click":function($event){dialog.value = false}}},[_vm._v(" Fechar ")])],1)])],1)]}}],null,false,2496375858)}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }