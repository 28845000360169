<template>
  <v-app>
    <v-main>
      <router-view></router-view>
      <!-- <notifications group="app" /> -->
      <notifications position="bottom center" group="app"></notifications>
      <a href="https://wa.me/5511939081255" target="_blank" class="whatsapp" :style="`background-image: url(./../../imgs/whatsapp.png)`"></a>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {},

  data: () => ({
    //
  }),

};


</script>

<style scoped>
.whatsapp {
  background-size: contain;
  width: 3rem;
  height: 3rem;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 999999999;
}
</style>
