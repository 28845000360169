<template>
  <div style="padding: 2rem">
    <v-form @submit.prevent="submit">
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="form.phone"
            label="Telefone (whatsapp)"
            v-mask="phoneMask"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="form.real_id"
            label="RG"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="form.issuer"
            label="Orgão Emissor"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
            v-model="form.issue_date"
            label="Data de emissão"
            v-mask="dateMask"
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
            v-model="form.nationality"
            label="Nacionalidade"
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
            v-model="form.mother_name"
            label="Nome da mãe"
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <v-select
            v-model="form.gender"
            label="Sexo"
            :items="gender"
          ></v-select>
        </v-col>

        <v-col cols="6">
          <v-text-field
            v-model="form.birth_date"
            label="Data de Nascimento"
            v-mask="dateMask"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-btn color="primary" class="mr-4" type="submit" :disabled="loading"
        >Avançar</v-btn
      >
    </v-form>
  </div>
</template>

<script>
import { VueMaskDirective } from 'v-mask';

export default {
  name: 'UpdateComponent',
  directives: {
    mask: VueMaskDirective,
  },
  data() {
    return {
      form: {
        real_id: '',
        issuer: '',
        issue_date: '',
        nationality: '',
        mother_name: '',
        gender: '',
        birth_date: '',
      },
      loading: false,
      gender: ['Masculino', 'Feminino'],
    };
  },
  computed: {
    phoneMask() {
      if (this.form?.phone?.length == 15) {
        return null;
      }
      return this.form?.phone?.length > 14
        ? '(##) #####-####'
        : '(##) ####-#####';
    },
    user() {
      return this.$store.state.user;
    },
    dateMask() {
      return '##/##/####';
    },
  },
  watch: {
    user(u) {
      console.log(u);
    },
  },
  mounted() {
    console.log('Dados do estudante', this.$store.state.user);
    setTimeout(() => {
      this.$set(this.form, 'phone', this.user?.phone);
      this.$set(this.form, 'real_id', this.user?.real_id);
      this.$set(this.form, 'issuer', this.user?.issuer);
      this.$set(this.form, 'issue_date', this.user?.issue_date);
      this.$set(this.form, 'nationality', this.user?.nationality);
      this.$set(this.form, 'mother_name', this.user?.mother_name);
      this.$set(this.form, 'gender', this.user?.gender);
      this.$set(this.form, 'birth_date', this.user?.birth_date);
    }, 600);
  },
  methods: {
    formatDate(date) {
      const date_split = date.split('/');
      if (date_split.length == 3) {
        const day = date_split[0];
        const month = date_split[1];
        const year = date_split[2];
        return `${year}-${month}-${day}`;
      }
      return date;
    },
    async submit() {
      this.loading = true;
      try {
        this.form.birth_date = this.formatDate(this.form.birth_date);
        this.form.issue_date = this.formatDate(this.form.issue_date);
        console.log('enviando form', this.form);
        const res = await this.$http.put(
          `/estudents/${this.$store.state.user.id}`,
          this.form,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem('tk')}`,
            },
          }
        );

        if (res.data.message == 'Dados atualizados com sucesso') {
          this.$store.commit('setUser', {
            ...this.$store.state.user,
            ...res.data.estudent,
          });
          this.$router.push({ name: 'Endereco' });
        }
      } catch (e) {
        alert(`Erro ao atualizar os dados:\n${e.response.data}`);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
