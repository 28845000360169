<template>
  <div
    :style="`background-image: url(./../../imgs/home/bg_section_1.jpg)`"
    class="content__section_1"
  >
    <v-container class="pa-0">
      <HeaderComponent />
      <div class="bg_section">
        <div class="section">
          <div class="txt_section">
            <h3 class="txt_sobre">Meu painel</h3>
            <div class="linha_caminho">
              <p class="inicial">
                <router-link to="/" class="link inicial"
                  >Página inicial</router-link
                >
              </p>
              <p class="inicial">&nbsp;&nbsp;>>&nbsp;&nbsp;</p>
              <p class="pagina">Meu painel</p>
            </div>
          </div>
        </div>
        <div class="section2">
          <div class="noticias">
            <div class="txt" v-if="user?.card_active?.status == 0">
              <h4 class="finalize_txt">
                Clique em Editar cadastro e preencha seus dados para finalizar o
                cadastro de sua carteirinha.
              </h4>
              <p class="paragrafo_finalize">
                Finalize o cadastro de sua carteirinha e aproveite todos os
                benefícios!
              </p>
            </div>
            <v-btn
              class="btn_action"
              to="/cadastro"
              v-if="user?.card_active?.status != 2"
              style="width: 100%; margin-bottom: 2rem"
            >
              <span class="centrarlizar_btn">
                <p class="txt_action">
                  {{
                    user?.card_active?.status == 1
                      ? "Edite seu cadastro"
                      : "Finalize seu cadastro"
                  }}
                </p>
              </span>
            </v-btn>
            <v-card
              class="card_renova"
              v-if="user?.available_renew && user?.next_card?.status != 1"
            >
              <div class="txt_card">
                <p class="txt_card_renovar" v-if="user?.next_card == null">
                  Renove agora sua carteirinha e pague menos!
                </p>

                <p class="txt_card_renovar" v-if="user?.next_card?.status == 0">
                  Carteirinha em análise. Atualize suas informações!
                </p>
                <p class="txt_card_renovar" v-if="user?.next_card?.status == 4">
                  Carteirinha em reanálise. Atualize suas informações!
                </p>
                <v-btn
                  class="btn_renovar"
                  :to="renovacao_link"
                  v-if="user?.available_renew && user?.next_card?.status != 1"
                  style="width: 100%; margin-bottom: 2rem"
                >
                  <p class="txt_btn_renovar" v-if="user?.next_card == null">
                    Renove sua carteirinha
                  </p>

                  <p
                    class="txt_btn_renovar"
                    v-if="
                      user?.next_card?.status != 2 && user?.next_card != null
                    "
                  >
                    Finalizar renovação
                  </p>
                </v-btn>
              </div>
              <div>
                <div class="efeito_img_card">
                  <img
                    src="./../../public/imgs/icones/icone_card.png"
                    alt="icone de usuario"
                  />
                </div>
              </div>
            </v-card>
            <v-btn
              class="btn_action"
              to="/cadastro"
              v-if="user?.next_card?.status == 1"
              style="width: 100%; margin-bottom: 2rem"
            >
              <span class="centrarlizar_btn">
                <p class="txt_action">Aguardando análise...</p>
              </span>
            </v-btn>
          </div>
          <v-row class="ma-0">
            <v-col cols="12" sm="12" md="12" lg="8" xl="6" class="pa-0">
              <CarteirinhaComponent />
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent";
import FooterComponent from "@/components/FooterComponent.vue";
import CarteirinhaComponent from "@/components/CarteirinhaComponent.vue";

export default {
  name: "DashboardPage",
  props: {},
  components: { HeaderComponent, FooterComponent, CarteirinhaComponent },
  data() {
    return {
      user: null,
      loading: false,
      noticiasItems: [],
      studentStatus: true,
      renovacao_link: null,
    };
  },
  computed: {},
  created() {
    this.init();
    this.getListNoticias();
  },
  methods: {
    getUser() {
      return this.$store.state.user;
    },
    formatDate(date) {
      let dateStr = date.split("T")[0];
      date = new Date(dateStr).toLocaleDateString("pt-br", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      return date;
    },
    async getListNoticias() {
      this.loading = true;
      await this.$http
        .get("/news")
        .then((r) => {
          this.loading = false;
          this.noticiasItems = r?.data?.data || [];
        })
        .catch((e) => {
          console.log(e.response || e);

          this.loading = false;
          this.$notify({
            group: "app",
            type: "error",
            title: "Ocorreu um erro",
            text: e.response?.data?.message || "Tente novamente mais tarde",
          });
        });
      this.loading = false;
    },
    async init() {
      this.loading = true;
      let user = this.getUser();

      await this.$http
        .get(`/estudents/${user.id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("tk")}` },
        })
        .then((r) => {
          this.loading = false;
          this.user = r?.data || [];
          this.studentStatus = this.user?.card_active?.status || 0;

          if (r?.data?.next_card?.status == 0) {
            this.renovacao_link = "/finalizar-renovacao";
          } else this.renovacao_link = "/renovacao";
        })
        .catch((e) => {
          console.log(e.response || e);
          this.loading = false;
          // this.$notify({
          //   group: 'app',
          //   type: 'error',
          //   title: 'Ocorreu um erro',
          //   text: e.response?.data?.message || 'Tente novamente mais tarde',
          // });
        });
    },
    async logout() {
      this.$store.commit("setUser", null);
      localStorage.removeItem("tk");
      setTimeout(() => {
        this.$router.push("/home");
      }, 550);
    },
  },
};
</script>

<style scoped>
.content__section_1 {
  background-size: auto 100%;
  background-position: right center;
}
.linha_caminho {
  display: flex;
}
.bg_fantasma {
  position: absolute;
  width: 90000vw;
  left: -500vw;
  z-index: 0;
  height: 500px;
  background-color: #fff;
}
.bg_section {
  border-radius: 1rem;
  position: relative;
  z-index: 1;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  backdrop-filter: blur(15px);
}
.txt_sobre {
  margin-top: 25px;
  font-size: 69px;
  line-height: 84px;
  color: white;
  font-weight: 700;
  font-family: yummo, sans-serif;
}
.inicial {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: white;
}
.pagina {
  font-size: 14px;
  line-height: 17px;
  color: white;
}
.centrarlizar_btn {
  /* background-color: #000; */
  display: flex;
  align-items: baseline;
}
.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  padding: 12px 24px;
  gap: 8px;
  width: 161px;
}
.sair,
.qrcode {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #1c4173;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.btn_sair {
  background: linear-gradient(90deg, #fa9500 0%, #fa6400 100%);
  border: 2px solid #fa9500;
  border-radius: 300px;
}
.btn_qr {
  background: linear-gradient(90deg, #ff8008 0%, #ffc837 100%);
  border: 2px solid #fa9500;
  border-radius: 300px;
}
.section {
  display: flex;
  justify-content: space-between;
}
.section2 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.info_carteirinha {
  position: absolute;
}
.img_selfie {
  position: absolute;
  margin: 65px 0 0 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100px;
  height: 120px;
}
.img_qrcode {
  position: absolute;
  margin: 190px 0 0 48px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 45px;
  height: 45px;
}
.img_qrcode_full {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 60vw;
  height: 60vh;
}
.name {
  position: absolute;
  margin: 65px 0 0 155px;
  width: 100px;
}
.document {
  position: absolute;
  margin: 135px 0 0 178px;
  width: 100px;
}
.real_id {
  position: absolute;
  margin: 147px 0 0 175px;
  width: 100px;
}
.birth_date {
  position: absolute;
  margin: 161px 0 0 230px;
  width: 100px;
}
.course {
  position: absolute;
  margin: 80px 0 0 155px;
  width: 100px;
}
.educational_level {
  position: absolute;
  margin: 95px 0 0 155px;
  width: 100px;
}
.educational_instituition {
  position: absolute;
  margin: 110px 0 0 155px;
  width: 100px;
}

.txt_carteirinha {
  font-size: 13px;
}

.noticias {
  width: 600px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: #1c4173; */
}

.flip-card {
  height: 250px;
  perspective: 250px;
}
.flip-card:hover .card {
  transform: rotateY(180deg);
}
.card {
  border: none;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 2s;
  margin: 15px 0;
}
.card-front,
.card-back {
  display: flex;
  backface-visibility: hidden;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.card-back {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  border: 2px solid rgba(15, 85, 155, 0.589);
  border-radius: 1rem;
  justify-content: center;
  padding: 0 25px;
  transform: rotateY(180deg);
  height: 250px;
  width: 100%;
}

.paragrafo {
  font-family: "Calibri", "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: white;
}
.btn_carteirinha {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  margin: 25px 0;
  gap: 8px;
  width: 320px;
  height: 46px;
  background: linear-gradient(90deg, #ff8008 0%, #ffc837 100%);
  border: 2px solid #fa9500;
  border-radius: 300px;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.carteirinha {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #1c4173;
}
.img_card {
  margin: 0;
}
.tag_noticia {
  width: 121px;
  height: 25px;
  background: linear-gradient(70.92deg, white 47.78%, #29b5a2 101.48%);
  border: 1px solid #1c4173;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.txt_noticia {
  font-weight: 700;
  font-size: 25px;
  line-height: 22px;
  text-align: center;
  color: #1c4173;
}
.txt_lancamento {
  margin: 0;
  padding: 0;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
.noticia_secundaria {
  width: 570px;
  /* height: 400px; */
  margin-left: 20px;
  /* background-color: #806d04; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.titulo_noticia_secundaria {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #000000;
}
.detalhes_noticia_secundaria {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
.data_noticia_principal {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #dddddd;
}
.data_noticia_secundaria {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #494949;
}
.card_noticia_secundaria {
  display: flex;
}
.img_card_noticia_secundaria {
  width: 190px;
  height: 190px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: 1rem;
  margin-right: 15px;
}
.txt_card_noticia_secundaria {
  width: 380px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.finalize_txt {
  font-family: "Calibri", "Libre Franklin";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: white;
}
.paragrafo_finalize {
  font-family: "Calibri", "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: white;
}
.btn_action {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  width: 161px;
  height: 46px;
  background: linear-gradient(90deg, #ff8008 0%, #ffc837 100%);
  border: 2px solid #fa9500;
  border-radius: 300px;
  text-decoration: none;
}
.txt_action {
  font-family: "Calibri", "Libre Franklin";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #1c4173;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.card_renova {
  width: 450px;
  height: 150px;
  background: linear-gradient(90deg, #ff8008 0%, #ffc837 100%);
  border: 2px solid #fa9500;
  border-radius: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.txt_card_renovar {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.txt_card {
  padding: 0 0px 0 20px;
  width: 280px;
}
.btn_renovar {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 16px;
  gap: 4px;
  width: 60px;
  height: 24px;
  background: #039001;
  border: 1px solid #039001;
  background: linear-gradient(90deg, #039001 0%, #039001 100%);
  border-radius: 10px;
  flex: none;
  order: 1;
  flex-grow: 0;
  text-decoration: none;
}
.txt_btn_renovar {
  font-family: "Libre Franklin";
  font-style: normal;
  margin: 10px 0 0 0;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  background: #039001;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.efeito_img_card {
  width: 150px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (orientation: portrait), (max-width: 1000px) {
  .txt_sobre {
    font-size: 35px;
  }
  .noticias,
  .section2 {
    width: 80vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .bg_section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .bg_fantasma {
    height: 540px;
  }
  .txt_section {
    height: 140px;
  }
  .section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .section2 {
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }
  .noticia_secundaria {
    width: auto;
    align-items: center;
    margin: 0;
  }
  .card_renova {
    margin-bottom: 25px;
  }
  .efeito_img_card {
    display: none;
  }
  .card_noticia_secundaria {
    flex-direction: column;
    align-items: center;
    /* background-color: #00d9f5; */
    margin-bottom: 25px;
    width: 80vw;
  }
  .txt_card_noticia_secundaria {
    width: 80vw;
  }
  .paragrafo {
    width: 90vw;
    text-align: center;
  }
  .btn_carteirinha {
    width: 90vw;
    align-items: center;
    text-align: center;
  }
  .linha_caminho {
    text-align: start;
  }
}
</style>
