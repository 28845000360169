<template>
  <div style="">
    <v-form @submit.prevent="submit">
      <v-row>
        <v-col cols="3">
          <v-text-field
            v-model="form.address_zipcode"
            label="CEP"
            required
            @keyup="addressByZipcode"
            v-mask="zipcodeMask"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="form.address_street"
            label="Logradouro"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="form.address_number"
            label="Número"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-model="form.address_complement"
            label="Complemento"
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-model="form.address_district"
            label="Bairro"
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-model="form.address_city"
            label="Cidade"
          ></v-text-field>
        </v-col>

        <v-col cols="3">
          <v-text-field
            v-model="form.address_state"
            label="Estado (UF)"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-btn color="primary" class="mr-4" type="submit" :disabled="loading"
        >Avançar</v-btn
      >
    </v-form>
  </div>
</template>

<script>
import { VueMaskDirective } from 'v-mask';
export default {
  name: 'AddressFormComponent',
  directives: {
    mask: VueMaskDirective,
  },
  computed: {
    zipcodeMask() {
      return '#####-###';
    },
    user() {
      return this.$store.state.user;
    },
  },
  data() {
    return {
      form: {
        address_zipcode: '',
        address_street: '',
        address_number: '',
        address_complement: '',
        address_district: '',
        address_city: '',
        address_state: '',
      },
      loading: false,
    };
  },
  mounted() {
    console.log('Dados do estudante', this.$store.state.user);
    setTimeout(() => {
      this.$set(this.form, 'address_zipcode', this.user?.address_zipcode);
      this.$set(this.form, 'address_street', this.user?.address_street);
      this.$set(this.form, 'address_number', this.user?.address_number);
      this.$set(this.form, 'address_complement', this.user?.address_complement);
      this.$set(this.form, 'address_district', this.user?.address_district);
      this.$set(this.form, 'address_city', this.user?.address_city);
      this.$set(this.form, 'address_state', this.user?.address_state);
    }, 500);
  },
  methods: {
    addressByZipcode() {
      console.log('CEP', this.form.address_zipcode);

      if (this.form.address_zipcode.length >= 8) {
        this.$http
          .get(`https://viacep.com.br/ws/${this.form.address_zipcode}/json/`)
          .then((response) => {
            console.log('CEP', response.data);
            this.form.address_street = response.data.logradouro;
            this.form.address_district = response.data.bairro;
            this.form.address_city = response.data.localidade;
            this.form.address_state = response.data.uf;
          })
          .catch((error) => {
            console.log('CEP', error);
          });
      }
    },

    async submit() {
      console.log('Dados do estudante', this.form);
      this.loading = true;

      try {
        const res = await this.$http.put(
          `estudents/${this.$store.state.user.id}/address`,
          this.form,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem('tk')}`,
            },
          }
        );
        this.loading = false;
        if (res.data.message == 'Endereço atualizado com sucesso') {
          this.$store.commit('setUser', {
            ...this.$store.state.user,
            ...res.data.estudent,
          });
          this.$router.push({ name: 'Escolaridade' });
        }
      } catch (e) {
        this.loading = false;
        console.log('Erro', e);
        alert(
          `Erro ao atualizar os dados do estudante: ${e.response.data.message}`
        );
      }
    },
  },
};
</script>

<style></style>
