import Vue from "vue";
import axios from "axios";
import store from "./store";
import Router from "vue-router";
import HomePage from "./pages/home";
import NoticiaPage from "./pages/noticia";
import SobrePage from "./pages/sobre";
import ContatoPage from "./pages/contato";
import DashboardPage from "./pages/dashboard";
import CadastroPage from "./pages/cadastro";
import EnderecoPage from "./pages/endereco";
import EscolaridadePage from "./pages/escolaridade";
import DocumentosPage from "./pages/documentos";
import RenovacaoPage from "./pages/renovacao";
import FinalizarRenovacaoPage from "./pages/finalizar-renovacao";
import TermosPage from "./pages/termos";
import ViewCardPage from "./pages/viewCard";
import FinishedPage from "./pages/finished";

const routes = [];
routes.push({ path: "*", name: "Pág. Inicial", component: HomePage });
routes.push({ path: "/", name: "Página Inicial", component: HomePage });
routes.push({
  path: "/noticia/:id",
  name: "Notícia",
  component: NoticiaPage,
});
routes.push({ path: "/sobre", name: "Sobre", component: SobrePage });
routes.push({ path: "/contato", name: "Contato", component: ContatoPage });
routes.push({
  path: "/dashboard",
  name: "Dashboard",
  component: DashboardPage,
  auth: true,
});

routes.push({
  path: "/cadastro",
  name: "Cadastro",
  component: CadastroPage,
  auth: true,
});

routes.push({
  path: "/endereco",
  name: "Endereco",
  component: EnderecoPage,
  auth: true,
});

routes.push({
  path: "/escolaridade",
  name: "Escolaridade",
  component: EscolaridadePage,
  auth: true,
});

routes.push({
  path: "/documentos",
  name: "Documentos",
  component: DocumentosPage,
  auth: true,
});

routes.push({
  path: "/renovacao",
  name: "Renovacao",
  component: RenovacaoPage,
  auth: true,
});

routes.push({
  path: "/finished",
  name: "Finished",
  component: FinishedPage,
  auth: false,
});

routes.push({
  path: "/finalizar-renovacao",
  name: "FinalizarRenovacao",
  component: FinalizarRenovacaoPage,
  auth: true,
});

routes.push({
  path: "/termos",
  name: "Termos",
  component: TermosPage,
});

routes.push({
  path: "/student-card/:email/:partnerToken",
  name: "Visualizar Carteirinha",
  component: ViewCardPage,
  meta: {
    blockNavigation: true,
  },
});

const router = new Router({
  history: true,
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

async function getUser() {
  console.log("Route");
  console.log(localStorage.getItem("id"));
  console.log(localStorage.getItem("tk"));
  store.commit("setLoadingRoute", true);
  await axios
    .get(`/estudents/${localStorage.getItem("id")}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("tk")}`,
      },
    })
    .then((r) => {
      console.log(r?.data);
      store.commit("setLoadingRoute", false);
      store.commit("setUser", r?.data);
      store.commit("setCardActive", r?.data?.card_active);
      store.commit("setNextCard", r?.data?.next_card);
    })
    .catch((e) => {
      console.log(e.response?.data);

      console.log("ERRO ao carregar os dados", e);

      store.commit("setLoadingRoute", false);
      localStorage.removeItem("tk");
      store.commit("setUser", null);
    });
}

router.beforeEach(async (to, from, next) => {
  let path = to.path;
  if (from.meta?.blockNavigation == true) {
    return next(from.path);
  }
  // console.log('path -> ', path);
  let matched = to.matched;
  if (matched.length == 0) {
    return next("/not-found");
  }

  if (path == "/not-found") return next();
  let found = routes?.find((r) => r.name == matched[0].name);
  if (!found) {
    return next("/not-found");
  }

  let user = store.state.user;
  if (!user && found.auth == true) {
    await getUser();
    user = store.state.user;
    if (!user) {
      return next("/");
    }
  }

  return next();
});

function afterEach(to) {
  Vue.nextTick(() => {
    let pageTitle = to.name;
    /** Ao navegar por quaisquer das subrotas do evento, atualizar-se-á o título da página */
    if (to?.params?.eventId && store.state.eventSelected) {
      pageTitle = `${pageTitle} | ${store.state.eventSelected?.name}`;
    }
    document.title = `Meia Entrada | ${pageTitle}`;
  });
}

router.afterEach((to) => {
  // console.log(to);
  store.commit("setPath", to.path);
  afterEach(to);
});

export default router;
