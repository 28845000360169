<template>
  <v-container>
    <nav class="navbar_personalizado navbar-expand-lg">
      <div class="btn_mobile_login">
        <div v-if="user && loading == false" class="btn_sair_dash">
          <v-btn class="btn btn_ajuste_mobile" to="/dashboard">
            <span class="centrarlizar_btn">
              <p class="dashboard">Dashboard</p>
            </span>
          </v-btn>
          <br />
          <v-btn class="btn_sair" @click="logout()">
            <div class="icone_logout">
              <img
                src="./../../public/imgs/icones/logout.png"
                alt="icone de usuario"
              />
            </div>
            <p class="sair">Sair</p>
          </v-btn>
        </div>

        <!-- {{ !user }} -->
        <!-- <v-progress-circular
          v-if="loading == true"
          indeterminate
        ></v-progress-circular> -->
        <v-dialog width="90vw" max-width="375px" fullscreen>
          <template v-slot:activator="{ on: dialog }">
            <v-btn
              v-if="user == false && loading == false"
              class="btn btn_ajuste_mobile"
              v-on="{ ...dialog }"
            >
              <span class="centrarlizar_btn">
                <div class="icone_login">
                  <img
                    src="./../../public/imgs/icones/login.png"
                    alt="icone de usuario"
                  />
                </div>
                <p class="login">Login</p>
              </span>
            </v-btn>
          </template>
          <template v-slot:default="dialog">
            <v-row
              class="ajuste_pop_up"
              justify="center"
              max-width="290"
              style="margin: 25vh 0"
            >
              <v-card>
                <v-card-title>Login</v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="email"
                          label="Email*"
                          :rules="[(v) => !!v || 'Campo Obrigatório']"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="password"
                          label="Senha*"
                          type="password"
                          :rules="[(v) => !!v || 'Campo Obrigatório']"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="dialog.value = false"> Fechar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    @click="login()"
                    :loading="loading"
                    dark
                  >
                    Entrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-row>
          </template>
        </v-dialog>
      </div>
      <div class="logo_mobile">
        <router-link to="/" class="link">
          <img
            class="logo_png logo_mobile_mobile desktop_none"
            src="./../../public/imgs/new_logo_landscape_white.png"
            alt="logo meia entrada todo branco"
          />
        </router-link>
      </div>
      <div class="menu">
        <div class="logo_mobile desktop_none">
          <input
            type="checkbox"
            id="checkbox4"
            class="checkbox4 visuallyHidden navbar-toggler btn_menu"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          />
          <label for="checkbox4">
            <div class="hamburger hamburger4">
              <span class="bar bar1"></span>
              <span class="bar bar2"></span>
              <span class="bar bar3"></span>
              <span class="bar bar4"></span>
              <span class="bar bar5"></span>
            </div>
          </label>
        </div>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav nav_bg">
            <div>
              <router-link to="/" class="link">
                <img
                  class="logo_png mobile_none"
                  src="./../../public/imgs/new_logo_landscape_white.png"
                  alt="logo meia entrada todo branco"
                />
              </router-link>
            </div>
            <li class="nav-item nav_titulo">
              <router-link to="/" class="nav-link">
                <p class="titulo_header">Início</p>
              </router-link>
            </li>
            <li class="nav-item nav_titulo">
              <router-link class="nav-link" to="/sobre">
                <p class="titulo_header">Sobre</p>
              </router-link>
            </li>
            <li class="nav-item nav_titulo">
              <router-link class="nav-link" to="/contato">
                <p class="titulo_header">Contato</p>
              </router-link>
            </li>
            <li class="nav-item nav_titulo">
              <a href="/#faca_sua" class="nav-link">
                <p class="titulo_header">Faça a sua</p>
              </a>
            </li>
            <li class="nav-item nav_titulo">
              <a href="/#faq" class="nav-link">
                <p class="titulo_header">FAQ</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </v-container>
</template>
<script>
export default {
  name: 'HeaderComponent',
  props: {},
  data() {
    return {
      loading: false,
      dialog: false,
      email: null,
      password: null,
      user: [],
      path: null,
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    async getUser() {
      this.path = this.$store.state.path;
      this.loading = true;
      if (
        localStorage.getItem('id') == null ||
        localStorage.getItem('tk') == null
      ) {
        this.user = false;
        this.loading = false;
        return this.user;
      } else {
        this.user = true;
        this.loading = false;
        return this.user;
      }
    },
    async logout() {
      this.$store.commit('setUser', null);
      localStorage.removeItem('tk');
      setTimeout(() => {
        this.$router.push('/home');
      }, 550);
    },
    async login() {
      this.loading = true;
      var params = {
        email: this.email,
        password: this.password,
      };
      console.log(params, 'params');

      await this.$http
        .post('/estudents/login', params)
        .then((r) => {
          this.loading = false;
          // console.log(r);
          // console.log(r?.data);
          this.$store.commit('setUser', r?.data);
          localStorage.setItem('tk', r?.data?.token);
          localStorage.setItem('id', r?.data?.id);
          this.$store.commit('setToken', r?.data?.token);
          this.$router.push('/dashboard');
        })
        .catch((e) => {
          console.log(e.response || e);
          this.loading = false;
          this.$notify({
            group: 'app',
            type: 'error',
            title: 'Ocorreu um erro',
            text: e.response?.data?.message || 'Tente novamente mais tarde',
          });
        });
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.desktop_none {
  display: none;
}
.mobile_none {
  display: initial;
}
.navbar_personalizado {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  /* background-color: #2c2860; */
}
.nav_titulo {
  font-weight: bold;
}

.logo_desktop img {
  width: 180px;
}

.titulo_header {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  padding: 0 10px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header_menu {
  width: 300px;
  display: flex;
  align-items: center;
}
.logo_png {
  margin-top: 15px;
  margin-right: 30px;
  width: 200px;
}
.icone_login img {
  width: 13px;
  margin: 5px;
  padding: 0 0 6px 0;
}
.icone_logout {
  /* width: 13px; */
  margin: 0 5px;
  /* padding: 0 0 6px 0; */
}
.centrarlizar_btn {
  /* background-color: #000; */
  display: flex;
  align-items: baseline;
}
.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  width: 161px;
  height: 46px;
  background: linear-gradient(90deg, #ff8008 0%, #ffc837 100%);
  border: 2px solid #fa9500;
  border-radius: 300px;
}
.login,
.dashboard {
  font-family: 'Calibri', 'Libre Franklin';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #1c4173;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.navbar-nav {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  height: 82px;
  padding: 0;
}
.btn_sair_dash {
  display: flex;
  justify-content: space-between;
  width: 300px;
  align-items: center;
}
.sair {
  padding: 0;
  margin: 0;
  color: white;
}
.btn_sair {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  width: max-content;
  height: 46px;
  background: transparent;
  background: linear-gradient(90deg, #1c4173 0%, #36afa3 100%);
  border: 2px solid #ffffff;
  border-radius: 0.5rem;
}
.btn_ajuste_mobile {
  border-radius: 0.5rem;
}
@media screen and (orientation: portrait), (max-width: 1000px) {
  .btn_sair_dash {
    flex-direction: column;
    align-items: center;
    width: 25%;
  }
  .mobile_none {
    display: none;
  }
  .desktop_none {
    display: block;
  }
  nav.mobile {
    display: block;
    background-color: #000 !important;
  }
  .ajuste_mobile {
    display: contents;
  }
  .logo_mobile {
    width: 41vw;
  }
  .navbar_personalizado {
    justify-content: space-around;
    align-items: center;
    /* background-color: red; */
    max-width: 1120px;
    height: 100px;
  }
  .logo_mobile,
  .btn_mobile_login {
    width: 20vw;
    /* background-color: #1c4173; */
  }
  .btn_ajuste_mobile,
  .logo_mobile_mobile {
    width: 20vw;
  }
  .menu {
    width: 20vw;
    height: 80px;
    /* background-color: #ff8008; */
    margin: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .navbar-nav {
    align-items: center;
  }
  .navbar_personalizado {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nav_bg {
    width: 100%;
    left: 0;
    position: absolute;
    z-index: 2;
    background-color: #1c4273e7;
    height: auto;
    border-radius: 1rem;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .login,
  .dashboard {
    font-size: 15px;
  }
}
@media screen and (orientation: portrait), (max-width: 378px) {
  .icone_login img {
    display: none;
  }
  .dashboard {
    font-size: 10px;
  }
  .login {
    font-size: 18px;
  }
}
.visuallyHidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
.hamburger {
  margin: 0 auto;
  margin-top: 30px;
  width: 30px;
  height: 30px;
  position: relative;
}

.hamburger .bar {
  padding: 0;
  width: 30px;
  height: 4px;
  background-color: #fff;
  display: block;
  border-radius: 4px;
  transition: all 0.4s ease-in-out;
  position: absolute;
}
.bar1 {
  top: 0;
}
.bar2,
.bar3 {
  top: 13.5px;
}
.bar3 {
  right: 0;
}
.bar4 {
  bottom: 0;
}

.hamburger2 .bar2,
.hamburger3 .bar3,
.hamburger4 .bar4 {
  top: 13.5px;
}

.hamburger4 .bar5 {
  bottom: 0px;
}

.hamburger4 .bar {
  transition: all 0.4s ease-in-out, transform 0.4s ease-in-out 0.4s;
}

.hamburger4 .bar2 {
  width: 1px;
  transform: rotate(90deg);
  left: 13.5px;
}

.hamburger4 .bar3 {
  width: 1px;
  left: 13.5px;
}

.checkbox4:checked + label > .hamburger4 > .bar1 {
  top: 13.5px;
  background-color: transparent;
}

.checkbox4:checked + label > .hamburger4 > .bar2 {
  left: 0px;
  width: 30px;
  transform: rotate(45deg);
}

.checkbox4:checked + label > .hamburger4 > .bar3 {
  left: 0;
  width: 30px;
  transform: rotate(-45deg);
}

.checkbox4:checked + label > .hamburger4 > .bar4 {
  background-color: transparent;
}

.checkbox4:checked + label > .hamburger4 > .bar5 {
  bottom: 13.5px;
  background-color: transparent;
}
</style>
