<template>
    <div class="text-center">
        <h3 class="txt_noticia">Certificado de escolaridade</h3>
        <h4>Seu pagamento foi confirmado, falta só mais um passo para renovar sua carteirinha.</h4>
        <p>precisamos do seu certificado de escolaridade.</p>
        
        <v-form @submit.prevent="submit">
            <v-row>
                <v-col cols="12">
                    <v-file-input
                        v-model="form.file_certificate"
                        label="Certificado"
                        required
                    ></v-file-input>
                </v-col>
            </v-row>
            <v-btn color="primary" class="mr-4" type="submit" :disabled="loading">Enviar</v-btn>
        </v-form>
    </div>
</template>

<script>

export default {
    name: "PaymentComponent",
    data() {
        return {
            loading: false,
            form: {
                file_certificate: "",
            },
        }
    },
    computed: {
        
    },
    mounted() {
        console.log("Envio do certificado");
    },
    methods: {
        async submit() {
            console.log("enviando form pro server", this.form);
            try {
                let dataForm = new FormData();
                for (let key in this.form) {
                    dataForm.append(key, this.form[key]);
                }
                this.loading = true;
                let response = await this.$http.post(`/estudents/${this.$store.state.user.id}/documents`, dataForm);
                
                console.log('response', response.data);

                await this.updateStatus();
                alert('Certificado enviado com sucesso!');

                this.$router.push({ name: 'Dashboard' });
                
            } catch (error) {
                this.loading = false;
                console.log(error);
            }
        },

        async updateStatus() {
            try {
                const params = {
                    status: 1,
                    card_estudent_id: this.$store.state.user.next_card.id,
                };
                console.log("parametros", params);
                const response = await this.$http.put(`/estudents/status`, params);
                if (response.data.sucess == true) {
                    let user = this.$store.state.user.next_card;
                    user.next_card.status = 1;
                    this.$store.commit('setUser', user);

                    this.$router.push({ name: 'Dashboard' });
                }
            } catch (e) {
                console.error(e);
            }
        }
    }
}
</script>

<style scoped>
    .text-center {
        text-align: center;
    }
    .txt_noticia {
        font-weight: 700;
        font-size: 25px;
        line-height: 22px;
        text-align: center;
        color: #1c4173;
    }
</style>