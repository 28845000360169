<template>
  <div
    class="content__section_1"
    :style="`background-image: url('./../../imgs/home/bg_section_1.jpg')`"
  >
    <v-container>
      <header-component></header-component>
      <div class="banner">
        <div class="banner_txt">
          <v-col cols="12" sm="12" md="4" lg="2" xl="2">
            <img
              class="img_mobile"
              src="./../../public/imgs/finalizar.png"
              alt="botão da loja de aplicativos da apple "
              width="100%"
            />
          </v-col>
          <p class="titulo_banner">Parabéns pela compra!</p>
          <p class="paragrafo">
            Para sua segurança você receberá em seu Whatsapp e também no seu email
            <strong>{{ user.email }}</strong>
            o usuário e a senha de acesso. Caso não receba o e-mail, verifique a caixa de SPAM.
          </p>
        </div>
      </div>
    </v-container>
    <div class="section_passos">
      <v-row justify="center">
        <h2 class="titulo_passo">Quais são os próximos passos?</h2>
      </v-row>
      <v-row justify="center" class="row_mobile">
        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
          <div class="card_passos">
            <div class="num_passo">
              <h1>1</h1>
            </div>
            <div class="txt_passos">
              Aguarde em seu Whatsapp ou e-mail cadastrado o login e senha que
              enviamos
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
          <div class="card_passos">
            <div class="num_passo">
              <h1>2</h1>
            </div>
            <div class="txt_passos">Baixe o aplicativo:</div>
            <div class="btn_lojas_app">
              <v-row justify="center">
                <v-col>
                  <a
                    href="https://itunes.apple.com/br/app/id1670378560?l=pt&mt=8"
                    target="_blank"
                    class="link"
                  >
                    <img
                      class="img_mobile"
                      src="./../../public/imgs/btns/btn_apple.png"
                      alt="botão da loja de aplicativos da apple "
                      width="100%"
                    />
                  </a>
                </v-col>
                <v-col>
                  <a
                    href="https://play.google.com/store/apps/details?id=br.com.meiaentradadigital&hl=pt_BR"
                    class="link"
                    target="_blank"
                  >
                    <img
                      class="img_mobile"
                      src="./../../public/imgs/btns/btn_google_play.png"
                      alt="botão da loja de aplicativos da google play"
                      width="100%"
                    />
                  </a>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
          <div class="card_passos">
            <div class="num_passo">
              <h1>3</h1>
            </div>
            <div class="txt_passos dif">
              Acesse o app com o usuário e senha enviados para seu e-mail
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
          <div class="card_passos">
            <div class="num_passo">
              <h1>4</h1>
            </div>
            <div class="txt_passos dif">
              Preencha suas informações dentro do app
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "FinishedPage",
  props: {},
  components: {
    HeaderComponent: () => import("../components/HeaderComponent.vue"),
  },
  data() {
    return {
      user: {},
    };
  },
  onmount() {
    this.user = this.$store.state.user;
    console.log("user", this.user);
    if (this.user?.email === undefined) {
      this.$router.push("/");
    }
  },
  methods: {
    linkContato() {
      window.open("https://meiaentradadigital.com.br/contato", "_blank");
    },
  },
};
</script>

<style scoped>
.section_passos {
  padding-bottom: 50px;
}
.card_passos {
  background-color: #0000003f;
  backdrop-filter: blur(15px);
  border-radius: 1rem;
  width: 210px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  padding: 0 10px;
}
.num_passo h1 {
  margin: 0;
  padding: 0;
}
.num_passo {
  width: 50px;
  height: 50px;
  margin-left: 70px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #e7ac00;
  border-radius: 2rem;
  color: #e7ac00;
}
.btn_lojas_app {
  margin: 16px 0 20px 0;
}
.txt_passos {
  color: #ffffff;
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}
.dif {
  margin: 0 0 15px 0;
}
.titulo_passo {
  font-family: yummo, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 25px;
}
.content__section_1 {
  background-position: right center;
  background-size: cover;
}
.centrarlizar_btn {
  display: flex;
  align-items: baseline;
  height: 25px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logos {
  width: 300px;
  display: flex;
  justify-content: space-between;
}
.logo_png {
  margin-top: 15px;
  width: 130px;
}
.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  width: 161px;
  height: 46px;
  background: linear-gradient(90deg, #ff8008 0%, #ffc837 100%);
  border: 2px solid #fa9500;
  border-radius: 0.5rem;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.fale {
  font-family: "Calibri";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #1c4173;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.banner {
  min-height: 40vh;
  margin: 10px 100px;
  /* display: flex; */
  align-items: center;
}
.banner_txt {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.titulo_banner {
  font-family: "yummo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 54px;
  text-align: center;
  color: #ffffff;
}
.paragrafo {
  margin-bottom: 3rem;
  font-family: "yummo", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: #ffffff;
}
@media screen and (orientation: portrait), (max-width: 1000px) {
  .row_mobile {
    display: grid;
  }
  .banner {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 0 100px 0;
  }
  .banner_txt {
    width: 90vw;
  }
  .paragrafo {
    margin: 0;
    padding: 0;
  }
  .img_mobile {
    width: 150px;
  }
  .titulo_passo {
    font-size: 25px;
  }
}
</style>
