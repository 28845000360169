<template>
  <div>
    <div
      class="footer__content"
      :style="`background-image: url(./../../imgs/footer/bg_footer.jpg)`"
    >
      <v-container class="pa-0">
        <v-row class="ma-0 footer">
          <v-col cols="12" sm="12" md="6" lg="4" xl="4">
            <img
              class="img_logo_meia_entrada"
              src="./../../public/imgs/new_logo_landscape_white.png"
              alt="Logo tipo do Meia Entrada Colorido "
            />
            <p class="txt_explicacao mt-6">
              O Meia Entrada Digital é uma plataforma tecnológica de suporte as
              Entidades que desejam inovar com segurança, praticidade e
              tecnologia na versão da Carteira Digital.
            </p>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2" xl="2">
            <p class="titulo_footer">Institucional</p>
            <router-link to="/" class="link">
              <p class="subtitulo_footer">Página inicial</p>
            </router-link>
            <router-link to="/contato" class="link">
              <p class="subtitulo_footer">Contato</p>
            </router-link>
            <router-link to="/sobre" class="link">
              <p class="subtitulo_footer">Sobre nós</p>
            </router-link>
            <a href="https://novo.app.meiaentradadigital.com.br/privacy-policy" target="_blank" class="link">
              <p class="subtitulo_footer">Política de Privacidade e Termos de uso</p>
            </a>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="4" xl="4">
            <p class="titulo_footer">Atendimento</p>
            <div class="linha">
              <img
                src="./../../public/imgs/icones/call.png"
                alt="icone de telefone"
                class="img__icon__footer"
              />
              <p class="subtitulo_footer mini">(11) 93908-1255</p>
            </div>
            <div class="linha">
              <img
                src="./../../public/imgs/icones/mail.png"
                alt="icone de email "
                class="img__icon__footer"
              />
              <a
                href="mailto:contato@meiaentradadigital.com.br"
                class="subtitulo_footer mini"
                >contato@meiaentradadigital.com.br</a
              >
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2" xl="2">
            <p class="titulo_footer">Baixe o app</p>
            <v-row class="ma-0">
              <v-col cols="12" class="pa-1">
                <a
                  href="https://itunes.apple.com/br/app/id1670378560?l=pt&mt=8"
                  class="link"
                  target="_blank"
                >
                  <v-responsive :aspect-ratio="16 / 5">
                    <div
                      class="image__store"
                      :style="`background-image: url(./../../imgs/btns/btn_apple.png)`"
                    ></div>
                  </v-responsive>
                </a>
              </v-col>
              <v-col cols="12" class="pa-1">
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=br.com.meiaentradadigital&hl=pt_BR"
                  class="link"
                >
                  <v-responsive :aspect-ratio="16 / 5">
                    <div
                      class="image__store"
                      :style="`background-image: url(./../../imgs/btns/btn_google_play.png)`"
                    ></div>
                  </v-responsive>
                </a>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="footer_copy">
      <p>Copyright © 2024 Meia Entrada. Todos os Direitos Reservados</p>
      <p>
        Desenvolvido por
        <a
          href="https://hoomweb.com"
          target="_blank"
          :style="`background-image: url(./../../imgs/hoomweb_logo_black.png)`"
        >
          <v-responsive :aspect-ratio="16 / 3"></v-responsive>
        </a>
      </p>
    </div>
  </div>
</template>
<script>
import * as EmailValidator from "email-validator";

export default {
  name: "FooterComponent",
  props: {},
  components: {
    // EmailValidator,
  },
  data() {
    return {
      loading: false,
      email: null,
    };
  },
  methods: {
    isValidEmail(email) {
      return EmailValidator.validate(email);
    },
    async emailNews() {
      //validação de campos
      if (!this.email) {
        alert(
          "Preencha o campo com Seu e-mail. Ele é obrigatórios para assinar nossa Newsletter. "
        );
        return;
      } else {
        if (this.email) {
          if (!this.isValidEmail(this.email)) {
            alert(
              "Preencha Seu e-mail corretamente. Ele é obrigatórios para assinar nossa Newsletter. "
            );
            return;
          }
        }
      }

      this.loading = true;
      var params = {
        email: this.email,
      };
      await this.$http
        .post("/newsletter", params)
        .then((r) => {
          this.loading = false;
          this.$notify({
            group: "app",
            type: "success",
            title: "Seu e-mail foi enviado",
            text: r?.data?.message || "Com sucesso!",
          });
        })
        .catch((e) => {
          console.log(e.response || e);
          this.loading = false;
          this.$notify({
            group: "app",
            type: "error",
            title: "Ocorreu um erro",
            text: e.response?.data?.message || "Tente novamente mais tarde",
          });
        });
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.img__icon__footer {
  width: 1rem;
}
.footer {
  position: relative;
  z-index: 1;
  padding: 20px 0px;
  height: auto;
  display: flex;
  backdrop-filter: blur(5px);
  padding: 2rem;
  border-radius: 0.5rem;
}
.footer_parceria {
  width: 400px;
}
.footer_colum {
  width: 200px;
}
.footer_colum_duplo {
  display: flex;
  flex-direction: column;
}
.footer_colum_duplo_titulo {
  display: flex;
  width: 500px;
  justify-content: space-between;
}
.footer_colum_attendimento {
  width: 400px;
}
.linha {
  display: flex;
  align-items: baseline;
}
.linha img {
  margin-right: 15px;
}
.footer_colum_app {
  width: 420px;
}
.img_logo_meia_entrada {
  width: 50%;
}

.txt_explicacao {
  font-family: "Calibri", "Libre Franklin";
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: white;
}
.titulo_footer {
  font-family: "yummo", "Libre Franklin";
  font-weight: 700;
  font-size: 23px;
  line-height: 28px;
  color: white;
}
.subtitulo_footer {
  font-family: "Calibri", "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: white;
  flex: none;
  order: 0;
  flex-grow: 0;
  white-space: break-spaces;
  word-break: break-word;
  width: 80%;
}
.txt_seu_email {
  font-family: "Calibri", "Libre Franklin";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: white;
}
.efeito_campo_newsletter {
  display: flex;
  align-items: baseline;
}

.btn_news {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  margin: 25px 0;
  gap: 8px;
  /* height: 53px !important; */
  background: linear-gradient(90deg, #ff8008 0%, #ffc837 100%);
  border: 2px solid #fa9500;
  /* border-radius: 300px; */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.txt_news {
  font-family: "Calibri", "Libre Franklin";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #1c4173;
}
.footer_copy {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #ffffff;
  padding: 1rem 0;
}
.footer_copy p {
  font-family: "Calibri", "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: black;
  text-align: center;
  display: flex;
  align-items: center;
  margin: unset;
}
.footer_copy p a {
  display: block;
  background-size: contain;
  background-position: center;
  width: 150px;
}
.link-politica {
  color: #fff;
  margin: 1rem 0;
  font-size: 0.9rem;
}
.ajuste_pop_up {
  margin: 130px 0 50px 0;
}

.link {
  width: 90%;
  display: flex;
}
.link .image__store {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
}

.footer__content {
  width: 100%;
  background-size: cover;
  background-position: left center;
}
@media screen and (orientation: portrait), (max-width: 1000px) {
  .subtitulo_footer.mini {
    font-size: .8rem;
    text-align: left;
  }
  .footer__content {
    background-position: center;
  }
  .footer {
    flex-direction: column;
    align-items: center;
  backdrop-filter: blur(50px);

  }
  .footer_colum_duplo_titulo,
  .efeito_campo_newsletter,
  .footer_colum_duplo,
  .footer_copy {
    flex-direction: column;
    align-items: center;
  }
  .footer_parceria,
  .footer_colum,
  .footer_colum_attendimento,
  .footer_colum_app,
  .footer_colum_news {
    width: 80vw;
    text-align: center;
  }
  .footer_parceria {
    height: 250px;
  }
  .linha {
    width: 80vw;
    justify-content: center;
    text-align: center;
  }
  .footer_fantasma {
    height: 1050px;
  }
  .footer_copy {
    align-items: center;
    justify-items: flex-end;
  }
}
</style>
