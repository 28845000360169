<template>
  <v-container>
    <HeaderComponent />
    <div class="bg_fantasma"></div>
    <div class="bg_section">
      <div class="section">
        <div class="txt_section">
          <h3 class="txt_sobre">Meu painel</h3>
          <div class="linha_caminho">
            <router-link to="/" class="link inicial">
              <p class="inicial">Página inicial</p>
            </router-link>
            <p class="inicial">&nbsp;&nbsp;>>&nbsp;&nbsp;</p>
            <p class="pagina">Finalizar Renovação de Carteirinha</p>
          </div>
        </div>
      </div>
      <div class="section2" style=""></div>
      <SendCertificate />
    </div>
    <FooterComponent />
  </v-container>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent';
import FooterComponent from '@/components/FooterComponentDash.vue';
import SendCertificate from '@/components/renew/SendCertificate.vue';

export default {
  name: 'RenovacaoPage',
  props: {},
  components: { HeaderComponent, FooterComponent, SendCertificate },
  data() {
    return {
      user: [],
      loading: false,
      noticiasItems: [],
      studentStatus: true,
    };
  },
  computed: {},
  created() {
    this.init();
    this.getListNoticias();
  },
  methods: {
    getUser() {
      return this.$store.state.user;
    },
    formatDate(date) {
      let dateStr = date.split('T')[0];
      date = new Date(dateStr).toLocaleDateString('pt-br', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
      return date;
    },
    async getListNoticias() {
      this.loading = true;
      await this.$http
        .get('/news')
        .then((r) => {
          this.loading = false;
          this.noticiasItems = r?.data?.data || [];
        })
        .catch((e) => {
          console.log(e.response || e);

          this.loading = false;
          this.$notify({
            group: 'app',
            type: 'error',
            title: 'Ocorreu um erro',
            text: e.response?.data?.message || 'Tente novamente mais tarde',
          });
        });
      this.loading = false;
    },
    async init() {
      this.loading = true;
      let user = this.getUser();

      await this.$http
        .get(`/estudents/${user.id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('tk')}` },
        })
        .then((r) => {
          this.loading = false;
          this.user = r?.data || [];
          this.studentStatus = this.user.card_active?.status || 0;
        })
        .catch((e) => {
          console.log(e.response || e);
          this.loading = false;
          // this.$notify({
          //   group: 'app',
          //   type: 'error',
          //   title: 'Ocorreu um erro',
          //   text: e.response?.data?.message || 'Tente novamente mais tarde',
          // });
        });
    },
    async logout() {
      this.$store.commit('setUser', null);
      localStorage.removeItem('tk');
      setTimeout(() => {
        this.$router.push('/home');
      }, 550);
    },
  },
};
</script>

<style scoped>
.linha_caminho {
  display: flex;
  margin-bottom: 50px;
}
.bg_fantasma {
  position: absolute;
  width: 90000vw;
  left: -500vw;
  z-index: 0;
  height: 450px;
  background-color: #fff;
}
.bg_section {
  border-radius: 1rem;
  position: relative;
  z-index: 1;
  height: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}
.txt_sobre {
  margin-top: 25px;
  font-size: 69px;
  line-height: 84px;
  color: #043980;
  font-weight: 700;
}
.inicial {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #043980;
}
.pagina {
  font-size: 14px;
  line-height: 17px;
  color: #043980;
}
.centrarlizar_btn {
  /* background-color: #000; */
  display: flex;
  align-items: baseline;
}
.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  padding: 12px 24px;
  gap: 8px;
  width: 161px;
}
.sair,
.qrcode {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #1c4173;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.btn_sair {
  background: linear-gradient(90deg, #fa9500 0%, #fa6400 100%);
  border: 2px solid #fa9500;
  border-radius: 300px;
}
.btn_qr {
  background: linear-gradient(90deg, #ff8008 0%, #ffc837 100%);
  border: 2px solid #fa9500;
  border-radius: 300px;
}
.section {
  display: flex;
  justify-content: space-between;
}
.section2 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.info_carteirinha {
  position: absolute;
}
.img_selfie {
  position: absolute;
  margin: 65px 0 0 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100px;
  height: 120px;
}
.img_qrcode {
  position: absolute;
  margin: 190px 0 0 48px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 45px;
  height: 45px;
}
.img_qrcode_full {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 60vw;
  height: 60vh;
}
.name {
  position: absolute;
  margin: 65px 0 0 155px;
  width: 100px;
}
.document {
  position: absolute;
  margin: 135px 0 0 178px;
  width: 100px;
}
.real_id {
  position: absolute;
  margin: 147px 0 0 175px;
  width: 100px;
}
.birth_date {
  position: absolute;
  margin: 161px 0 0 230px;
  width: 100px;
}
.course {
  position: absolute;
  margin: 80px 0 0 155px;
  width: 100px;
}
.educational_level {
  position: absolute;
  margin: 95px 0 0 155px;
  width: 100px;
}
.educational_instituition {
  position: absolute;
  margin: 110px 0 0 155px;
  width: 100px;
}

.txt_carteirinha {
  font-size: 13px;
}

.noticias {
  width: 600px;
  height: auto;
  /* background-color: #1c4173; */
}
.img_section {
  width: 400px;
}

.flip-card {
  height: 250px;
  perspective: 250px;
}
.flip-card:hover .card {
  transform: rotateY(180deg);
}
.card {
  border: none;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 2s;
  margin: 15px 0;
}
.card-front,
.card-back {
  display: flex;
  backface-visibility: hidden;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.card-back {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  border: 2px solid rgba(15, 85, 155, 0.589);
  border-radius: 1rem;
  justify-content: center;
  padding: 0 25px;
  transform: rotateY(180deg);
  height: 250px;
  width: 100%;
}

.paragrafo {
  font-family: 'Calibri', 'Libre Franklin';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #043980;
}
.btn_carteirinha {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  margin: 25px 0;
  gap: 8px;
  width: 320px;
  height: 46px;
  background: linear-gradient(90deg, #ff8008 0%, #ffc837 100%);
  border: 2px solid #fa9500;
  border-radius: 300px;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.carteirinha {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #1c4173;
}
.img_card {
  margin: 0;
}
.tag_noticia {
  width: 121px;
  height: 25px;
  background: linear-gradient(70.92deg, #043980 47.78%, #29b5a2 101.48%);
  border: 1px solid #1c4173;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.txt_noticia {
  font-weight: 700;
  font-size: 25px;
  line-height: 22px;
  text-align: center;
  color: #1c4173;
}
.txt_lancamento {
  margin: 0;
  padding: 0;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
.noticia_secundaria {
  width: 570px;
  /* height: 400px; */
  margin-left: 20px;
  /* background-color: #806d04; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.titulo_noticia_secundaria {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #000000;
}
.detalhes_noticia_secundaria {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
.data_noticia_principal {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #dddddd;
}
.data_noticia_secundaria {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #494949;
}
.card_noticia_secundaria {
  display: flex;
}
.img_card_noticia_secundaria {
  width: 190px;
  height: 190px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: 1rem;
  margin-right: 15px;
}
.txt_card_noticia_secundaria {
  width: 380px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media screen and (orientation: portrait), (max-width: 1000px) {
  .bg_fantasma {
    height: 1030px;
  }
  .txt_section {
    height: 140px;
  }
  .section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .section2 {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .noticia_secundaria {
    width: auto;
    align-items: center;
    margin: 0;
  }
  .card_noticia_secundaria {
    flex-direction: column;
    align-items: center;
    /* background-color: #00d9f5; */
    margin-bottom: 25px;
    width: 80vw;
  }
  .txt_card_noticia_secundaria {
    width: 80vw;
  }
  .paragrafo {
    width: 90vw;
    text-align: center;
  }
  .btn_carteirinha {
    width: 90vw;
    align-items: center;
    text-align: center;
  }
  .linha_caminho {
    text-align: start;
  }
}
</style>
