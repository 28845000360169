<template>
  <v-container>
    <HeaderComponent />
    <div class="bg_fantasma"></div>
    <div class="bg_section">
      <div class="linha_caminho">
        <router-link to="/" class="link inicial">
          <p class="inicial">Página inicial</p>
        </router-link>
        <p class="inicial">&nbsp;&nbsp;>>&nbsp;&nbsp;</p>
        <router-link to="/noticias" class="link inicial">
          <p class="pagina">NOTÍCIAS</p>
        </router-link>
        <p class="inicial">&nbsp;&nbsp;>>&nbsp;&nbsp;</p>
        <p class="pagina">{{ noticiaItem.title }}</p>
      </div>
      <h3 class="txt_noticias">{{ noticiaItem.title }}</h3>
      <p class="txt_data" v-if="noticiaItem.created_at">
        {{ formatDate(noticiaItem.created_at) }}
      </p>
      <div class="tag_noticia">
        <p class="txt_lancamento">Lançamentos</p>
      </div>
      <div class="section">
        <div
          class="section_img"
          :style="`background-image: url(${noticiaItem.image_url});`"
        ></div>
        <div class="section_txt">
          <p class="txt_conteudo">
            {{ noticiaItem.content }}
          </p>
        </div>
      </div>
    </div>
    <FooterComponent />
  </v-container>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent';
import FooterComponent from '@/components/FooterComponent.vue';

export default {
  props: ['active', 'finish', 'item'],
  name: 'NoticiasPage',
  components: {
    HeaderComponent,
    FooterComponent,
  },
  data() {
    return {
      loading: false,
      noticiaItem: [],
    };
  },
  computed: {},
  created() {
    this.getNoticia();
  },
  methods: {
    formatDate(date) {
      let dateStr = date.split('T')[0];
      date = new Date(dateStr).toLocaleDateString('pt-br', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
      return date;
    },
    async getNoticia() {
      this.loading = true;
      var url_ = location.href;
      var id = url_.substring(url_.lastIndexOf('=') + 1, url_.length);
      console.log(id, 'id bb');
      await this.$http
        .get(`/news/${id}`)
        .then((r) => {
          this.loading = false;
          console.log(r?.data);
          this.noticiaItem = r?.data || [];
        })
        .catch((e) => {
          console.log(e.response || e);

          this.loading = false;
          this.$notify({
            group: 'app',
            type: 'error',
            title: 'Ocorreu um erro',
            text: e.response?.data?.message || 'Tente novamente mais tarde',
          });
        });
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.linha {
  margin-top: 30px;
}
.bg_fantasma {
  position: absolute;
  width: 90000vw;
  left: -500vw;
  z-index: 0;
  height: 850px;
  background-color: #fff;
}
.bg_section {
  border-radius: 1rem;
  position: relative;
  z-index: 1;
  height: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.txt_noticias {
  font-size: 69px;
  line-height: 84px;
  color: #043980;
  font-weight: 700;
}
.inicial {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #043980;
}
.pagina {
  font-size: 14px;
  line-height: 17px;
  color: #043980;
}
.txt_data {
  font-family: 'Calibri', 'Libre Franklin';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #494949;
}
.tag_noticia {
  width: 121px;
  height: 25px;
  background: linear-gradient(70.92deg, #043980 47.78%, #29b5a2 101.48%);
  border: 1px solid #1c4173;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.txt_lancamento {
  margin: 0;
  padding: 0;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
.section {
  display: flex;
  justify-content: space-between;
}
.section_img {
  width: 500px;
  height: 523px;
  /* background-color: #043980; */
  border-radius: 1rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.section_txt {
  margin: 0 20px;
  /* background-color: #043980; */
}
.txt_conteudo {
  font-family: 'Calibri', 'Libre Franklin';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #494949;
}
.linha_caminho {
  margin: 15px 0;
  display: flex;
  align-items: center;
  /* margin-bottom: 50px; */
}
@media screen and (orientation: portrait), (max-width: 1000px) {
  .bg_fantasma {
    height: 1600px;
  }
  .section {
    flex-direction: column;
    align-items: center;
  }
  .txt_noticias {
    font-size: 30px;
    line-height: 1.5;
    text-align: center;
  }
  .section_img {
    width: 80vw;
  }
  .txt_conteudo {
    text-align: center;
  }
  .linha_caminho {
    text-align: start;
  }
}
</style>
