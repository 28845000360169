<template>
  <v-container>
    <div class="footer">
      <div class="footer_parceria">
        <br />
        <v-flex>
          <v-row class="justify-start align-baseline">
            <v-col md="4">
              <img
                class="img_logo_meia_entrada"
                src="./../../public/imgs/logos/logo.png"
                alt="Logo tipo do Meia Entrada Colorido "
              />
            </v-col>
          </v-row>
          <p class="txt_explicacao">
            O Meia Entrada é uma plataforma tecnológica de suporte as Entidades
            que desejam inovar com segurança, praticidade e tecnologia na versão
            da Carteira Digital.
          </p>
        </v-flex>
      </div>
      <div class="footer_colum">
        <p class="titulo_footer">Institucional</p>
        <router-link to="/" class="link">
          <p class="subtitulo_footer">Página inicial</p>
        </router-link>
        <router-link to="/parceiros" class="link">
          <p class="subtitulo_footer">Parceiros</p>
        </router-link>
        <router-link to="/noticias" class="link">
          <p class="subtitulo_footer">Notícias</p>
        </router-link>
        <router-link to="/contato" class="link">
          <p class="subtitulo_footer">Contato</p>
        </router-link>
        <router-link to="/sobre" class="link">
          <p class="subtitulo_footer">Sobre nós</p>
        </router-link>
        <router-link to="/termos" class="link">
          <p class="subtitulo_footer">Termos</p>
        </router-link>
      </div>
      <div class="footer_colum_duplo">
        <div class="footer_colum_duplo_titulo">
          <div class="footer_colum_attendimento">
            <p class="titulo_footer">Atendimento</p>
            <div class="linha">
              <img
                src="./../../public/imgs/icones/call_b.png"
                alt="icone de telefone"
              />
              <p class="subtitulo_footer">(11) 93908-1255</p>
            </div>
            <div class="linha">
              <img
                src="./../../public/imgs/icones/mail_b.png"
                alt="icone de email "
              />
              <p class="subtitulo_footer">contato@meiaentradadigital.com.br</p>
            </div>
          </div>
          <div class="footer_colum_app">
            <p class="titulo_footer">Baixe o app</p>
            <v-flex class="btn_app">
              <a
                href="https://itunes.apple.com/br/app/id1670378560?l=pt&mt=8"
                class="link"
                target="_blank"
              >
                <img
                  src="./../../public/imgs/btns/btn_apple.png"
                  alt="botão da loja de aplicativos da apple "
                />
              </a>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=br.com.meiaentradadigital"
                class="link"
              >
                <img
                  src="./../../public/imgs/btns/btn_google_play.png"
                  alt="botão da loja de aplicativos da google play"
                />
              </a>
            </v-flex>
          </div>
        </div>
        <div class="footer_colum_news">
          <p class="titulo_footer">Assine nossa newsletter</p>
          <p class="txt_seu_email">Seu e-mail</p>
          <div class="efeito_campo_newsletter">
            <v-row>
              <v-col md="12">
                <v-text-field
                  class="form_email"
                  v-model="email"
                  outlined
                  dark
                  placeholder="email@site.com.br"
                  label="Seu e-mail"
                  :rules="[
                    (v) => !!v || 'Campo obrigatório',
                    (v) => isValidEmail(v) || 'E-mail Inválido!',
                  ]"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn
              class="btn_news"
              type="submit"
              :loading="loading"
              @click.prevent="emailNews()"
            >
              <span class="centrarlizar_btn">
                <p class="txt_news">Assinar newsletter</p>
              </span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_fantasma"></div>
    <div class="footer_copy">
      <p>
        Copyright © 2024 Meia Entrada. Todos os Direitos Reservados<br />
        <router-link to="termos" class="link-politica">Política de Privacidade e Termos de uso</router-link>
      </p>

      <p>Desenvolvido por HoomWeb</p>
    </div>
  </v-container>
</template>
<script>
import * as EmailValidator from 'email-validator';

export default {
  name: 'FooterComponent',
  props: {},
  components: {
    // EmailValidator,
  },
  data() {
    return {
      loading: false,
      email: null,
    };
  },
  methods: {
    isValidEmail(email) {
      return EmailValidator.validate(email);
    },
    async emailNews() {
      //validação de campos
      if (!this.email) {
        alert(
          'Preencha o campo com Seu e-mail. Ele é obrigatórios para assinar nossa Newsletter. '
        );
        return;
      } else {
        if (this.email) {
          if (!this.isValidEmail(this.email)) {
            alert(
              'Preencha Seu e-mail corretamente. Ele é obrigatórios para assinar nossa Newsletter. '
            );
            return;
          }
        }
      }

      this.loading = true;
      var params = {
        email: this.email,
      };
      await this.$http
        .post('/newsletter', params)
        .then((r) => {
          this.loading = false;
          this.$notify({
            group: 'app',
            type: 'success',
            title: 'Seu e-mail foi enviado',
            text: r?.data?.message || 'Com sucesso!',
          });
        })
        .catch((e) => {
          console.log(e.response || e);
          this.loading = false;
          this.$notify({
            group: 'app',
            type: 'error',
            title: 'Ocorreu um erro',
            text: e.response?.data?.message || 'Tente novamente mais tarde',
          });
        });
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.footer_fantasma {
  position: absolute;
  width: 90000vw;
  left: -500vw;
  z-index: 0;
  height: 76px;
  background-color: #fff;
}
.footer {
  position: relative;
  z-index: 1;
  padding: 20px 0px;
  height: auto;
  display: flex;
}
.footer_parceria {
  width: 400px;
}
.footer_colum {
  width: 200px;
}
.footer_colum_duplo {
  display: flex;
  flex-direction: column;
}
.footer_colum_duplo_titulo {
  display: flex;
  width: 500px;
  justify-content: space-between;
}
.footer_colum_attendimento {
  width: 400px;
}
.linha {
  display: flex;
  align-items: baseline;
}
.linha img {
  margin-right: 15px;
}
.footer_colum_app {
  width: 420px;
}
.img_logo_meia_entrada {
  width: 207px;
  height: 64px;
}

.txt_explicacao {
  font-family: 'Calibri', 'Libre Franklin';
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.titulo_footer {
  font-family: 'Calibri', 'Libre Franklin';
  font-weight: 700;
  font-size: 23px;
  line-height: 28px;
  color: #ffffff;
}
.subtitulo_footer {
  font-family: 'Calibri', 'Libre Franklin';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.txt_seu_email {
  font-family: 'Calibri', 'Libre Franklin';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.efeito_campo_newsletter {
  display: flex;
  align-items: baseline;
}

.btn_news {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  margin: 25px 0;
  gap: 8px;
  /* height: 53px !important; */
  background: linear-gradient(90deg, #ff8008 0%, #ffc837 100%);
  border: 2px solid #fa9500;
  /* border-radius: 300px; */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.txt_news {
  font-family: 'Calibri', 'Libre Franklin';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #1c4173;
}
.footer_copy {
  margin: 15px 0 0 0;
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
}
.footer_copy p {
  font-family: 'Calibri', 'Libre Franklin';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}

.link-politica {
  color: #000000;
  margin: 1rem 0;
  font-size: 0.9rem;
}
.ajuste_pop_up {
  margin: 130px 0 50px 0;
}
@media screen and (orientation: portrait), (max-width: 1000px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }
  .footer_colum_duplo_titulo,
  .efeito_campo_newsletter,
  .footer_colum_duplo,
  .footer_copy {
    flex-direction: column;
    align-items: center;
  }
  .footer_parceria,
  .footer_colum,
  .footer_colum_attendimento,
  .footer_colum_app,
  .footer_colum_news {
    width: 80vw;
    text-align: center;
  }
  .footer_parceria {
    height: 250px;
  }
  .linha {
    width: 80vw;
    justify-content: center;
    text-align: center;
  }
  .footer_fantasma {
    height: 1100px;
  }
  .footer_copy {
    margin: 100px 0 0 0;
    align-items: center;
    justify-items: flex-end;
  }
}
</style>
