<template>
  <div v-if="pix" class="text-center">
    <h3 class="txt_noticia">Renovação de Carteirinha</h3>
    <h4>Para renovar sua carteirinha, faça o pagamento</h4>
    <p>
      após o pagamento você será redirecionado direto para a página de
      confirmação dos dados.
    </p>
    <div class="img-pix">
      <img :src="pix.urlImagemQrCode" alt="QRCode" />
    </div>
    <p class="text-center">{{ pix.pixCopiaECola }}</p>
    <p class="text-center">
      <a href="#">copiar código</a>
    </p>
  </div>
  <div v-else>
    <v-progress-circular indeterminate></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: 'PaymentComponent',
  data() {
    return {
      loading: false,
      pix: null,
      intervalCheck: null,
    };
  },
  computed: {},
  mounted() {
    console.log('Gerando pix para pagamento');
    this.getPix();
  },
  unmounted() {
    clearInterval(this.intervalCheck);
  },
  methods: {
    getPix() {
      this.loading = true;

      if (this.$store.state.user.next_card) {
        console.log(
          'Dados da nova carteirinha',
          this.$store.state.user.next_card
        );

        this.$http
          .get(
            `card-estudents/pix/${this.$store.state.user.next_card.payment_id}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('tk')}`,
              },
            }
          )
          .then(async (res) => {
            if (res.data.data.status == 'ATIVA') {
              this.pix = res.data.data;
              console.log('Dados do pix', this.pix);
              this.intervalCheck = setInterval(this.checkPayment, 5000);
              return;
            }

            await this.createNewPix();
          })
          .catch((e) => {
            console.error(e);
          });
      }
      this.createNewPix();
    },

    async createNewPix() {
      try {
        const res = await this.$http.post(
          `/card-estudents/renew`,
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('tk')}`,
            },
          }
        );
        this.loading = false;
        this.pix = res.data.data;
        await this.updateUser();
        this.intervalCheck = setInterval(this.checkPayment, 5000);
      } catch (e) {
        console.error(e);
      }
    },

    async updateUser() {
      const res = await this.$http.get(
        `/estudents/${this.$store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tk')}`,
          },
        }
      );
      this.$store.commit('setUser', res.data.data);
    },

    async checkPayment() {
      const res = await this.$http.get(
        `/card-estudents/is-paid/${this.pix.txid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tk')}`,
          },
        }
      );
      if (res.status == 200 && res.data.data.paid == true) {
        clearInterval(this.intervalCheck);
        this.$router.push({ name: 'FinalizarRenovacao' });
      }

      console.error('Erro ao checar pagamento');
    },
  },
};
</script>

<style scoped>
.img-pix {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-center {
  text-align: center;
}
.txt_noticia {
  font-weight: 700;
  font-size: 25px;
  line-height: 22px;
  text-align: center;
  color: #1c4173;
}
</style>
