<template>
  <v-row class="ma-0" ref="studentcardcontainer">
    <v-col class="ma-0 pa-0">
      <v-progress-circular
        v-if="loading == true"
        indeterminate
      ></v-progress-circular>

      <v-responsive :aspect-ratio="currentAspectRatio">
        <div class="container__card">
          <div v-if="user" class="card__general">
            <div class="card__front" v-if="showFront">
              <div class="flag_status" v-if="user?.card_active?.status != 2">
                <h4 class="status_card">
                  {{ user?.card_active?.status_label }}
                </h4>
              </div>
              <div
                :style="{
                  position: 'absolute',
                  // backgroundColor: 'red',
                  backgroundImage: `url(${user?.files[0].url || ''})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  ...getPositions('picture'),
                }"
              ></div>
              <div
                v-if="user?.card_active?.status == 2"
                :style="{
                  position: 'absolute',
                  // backgroundColor: 'red',
                  backgroundImage: `url(${user?.files[5]?.url ?? ''})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  ...getPositions('qrcode'),
                }"
              ></div>
              <h1
                class="txt__card"
                :style="{
                  fontSize: getPercentageOfHeight(3.7),
                  ...getPositions('name'),
                }"
              >
                {{ user?.name }}
              </h1>
              <h1
                class="txt__card"
                :style="{
                  fontSize: getPercentageOfHeight(3.7),
                  ...getPositions('document'),
                }"
              >
                {{ user?.document }}
              </h1>
              <h1
                class="txt__card"
                :style="{
                  fontSize: getPercentageOfHeight(3.7),
                  ...getPositions('real_id'),
                }"
              >
                {{ user?.real_id || "" }}
              </h1>
              <h1
                class="txt__card"
                :style="{
                  fontSize: getPercentageOfHeight(3.7),
                  ...getPositions('birth_date'),
                }"
              >
                {{ $formatDate(user?.birth_date, false, "") }}
              </h1>
              <h1
                class="txt__card"
                :style="{
                  fontSize: getPercentageOfHeight(3.7),
                  ...getPositions('course'),
                }"
              >
                {{ user?.course || "" }}
              </h1>
              <h1
                class="txt__card"
                :style="{
                  fontSize: getPercentageOfHeight(3.7),
                  ...getPositions('educational_instituition_name'),
                }"
              >
                {{ user?.educational_instituition_name ?? "" }}
              </h1>
              <h1
                class="txt__card"
                :style="{
                  fontSize: getPercentageOfHeight(3.7),
                  ...getPositions('educational_level_name'),
                }"
              >
                {{ user?.educational_level_name ?? "" }}
              </h1>

              <h1
                class="txt__card register"
                :style="{
                  fontSize: getPercentageOfHeight(3.7),
                  ...getPositions('register'),
                }"
              >
                {{ user?.register ?? "" }}
              </h1>
              <h1
                class="txt__card no__uppercase"
                v-if="user?.card_active?.status == 2"
                :style="{
                  fontSize: getPercentageOfHeight(3.7),
                  ...getPositions('code'),
                }"
              >
                {{ user?.code ?? "" }}
              </h1>

              <img
                class="img_card"
                width="100%"
                height="100%"
                :src="cardFront"
                alt="carteirinha do estudantes com elementos em volta como pipoca oculos em 3d guitarra e um gol com uma bola de futebol"
              />
              <!-- src="./../../public/imgs/carteirinha2023.png" -->
            </div>
            <div class="card__back" v-if="!showFront">
              <img
                class="img_card"
                width="100%"
                height="100%"
                :src="cardVerse"
                alt="carteirinha do estudantes com elementos em volta como pipoca oculos em 3d guitarra e um gol com uma bola de futebol"
              />
              <!-- src="./../../public/imgs/verso2023.png" -->
            </div>
          </div>
          <div v-else>Carteirinha indisponível no momento</div>
        </div>
      </v-responsive>
      <v-dialog
        v-if="user?.card_active?.status == 2"
        :width="studentCardWidth"
        :scrollable="false"
        :fullscreen="true"
      >
        <template v-slot:activator="{ on: dialog }">
          <v-row
            :style="{
              margin: 'unset',
              marginTop: getPercentageOfHeight(3),
            }"
          >
            <v-col cols="6" class="py-0">
              <v-btn
                class="btn btn__action pa-0 ma-0 mx-auto"
                v-on="{ ...dialog }"
                block
                :style="{
                  fontSize: getPercentageOfHeight(3.7),
                }"
              >
                Ver QrCode
              </v-btn>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-btn
                class="btn btn__action pa-0 ma-0 mx-auto"
                block
                :style="{
                  fontSize: getPercentageOfHeight(3.7),
                }"
                @click="showFront = !showFront"
              >
                Ver {{ showFront ? "Verso" : "Frente" }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-slot:default="dialog">
          <v-card :width="studentCardWidth" :height="studentCardHeight">
            <v-card-text class="pb-0" style="height: 100%">
              <div class="content__qrcode">
                <div
                  class="img_qrcode_full"
                  :style="`background-image: url(${user?.files[5].url});`"
                ></div>
                <v-btn
                  class="btn centrarlizar_btn btn_sair"
                  @click="dialog.value = false"
                  style="max-height: 20%"
                  block
                >
                  Fechar
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["data"],
  name: "CarteirinhaComponent",
  data() {
    return {
      showFront: true,
      user: null,
      loading: false,
      studentCardHeight: null,
      studentCardWidth: null,
      aspectRatios: {
        2023: 160 / 101,
      },
      positions: {
        2023: {
          picture: { top: 25.7, left: 5.6, height: 48.4, width: 24 },
          qrcode: { top: 75.2, left: 11.7, height: 18.2, width: 11.4 },
          name: { top: 25, left: 38.5 },
          course: { top: 33, left: 38.5 },
          educational_instituition_name: { top: 39, left: 38.5 },
          educational_level_name: { top: 45, left: 38.5 },
          document: { top: 54.5, left: 45 },
          real_id: { top: 59.6, left: 43.5 },
          birth_date: { top: 64.9, left: 58 },
          register: { top: 70.3, left: 55 },
          code: { top: 83.3, left: 38.5 },
        },
      },
    };
  },
  computed: {
    currentCard() {
      return this.user?.card_active ?? this.user?.next_card ?? null;
    },
    currentYear() {
      return Number.parseInt(
        `${this.currentCard?.card?.name ?? new Date().getFullYear()}`
      );
    },
    currentAspectRatio() {
      return this.aspectRatios[this.currentYear];
    },
    fullAspectRatio() {
      if (!this.studentCardWidth || !this.studentCardHeight) return null;
      return this.studentCardWidth / this.studentCardHeight;
    },
    currentPosition() {
      return this.positions[this.currentYear];
    },
    cardFront() {
      return `/imgs/carteirinha${this.currentYear}.png`;
    },
    cardVerse() {
      return `/imgs/verso${this.currentYear}.png`;
    },
  },
  watch: {
    user() {
      this.watchStudentCardSize();
    },
    fullAspectRatio(v) {
      if (v) {
        this.$debounce("emitMessage", () => {
          window.parent.postMessage(
            {
              type: "newHeightStudentCard",
              value: this.studentCardHeight,
            },
            "*"
          );
          console.log("sent post message : ", this.studentCardHeight);
        });
      }
    },
  },

  mounted() {
    this.user = this.data ?? this.$store.state.user;
    this.init();
    const app = document.getElementById("app");
    if (app) {
      app.classList.add("transparent__bg");
    }
    document.body.classList.add("transparent__bg");
  },

  beforeDestroy() {
    this.removeObservers();
    const app = document.getElementById("app");
    if (app) {
      app.classList.remove("transparent__bg");
    }
    document.body.classList.remove("transparent__bg");
  },

  methods: {
    init() {
      this.$nextTick(() => {
        this.setSizeStudentCard();
      });
    },
    setSizeStudentCard() {
      if (this.$refs.studentcardcontainer) {
        this.studentCardHeight =
          this.$refs.studentcardcontainer?.clientHeight ??
          this.$refs.studentcardcontainer[0]?.clientHeight ??
          300;
        this.studentCardWidth =
          this.$refs.studentcardcontainer?.clientWidth ??
          this.$refs.studentcardcontainer[0]?.clientWidth ??
          300;
      } else {
        this.studentCardHeight = 300;
        this.studentCardWidth = 300;
      }
    },
    removeObservers() {
      if (this.resizeObserverStudentCard) {
        this.resizeObserverStudentCard.disconnect();
      }
    },
    watchStudentCardSize() {
      if (!this.$refs.studentcardcontainer) return;
      this.removeObservers();
      this.setSizeStudentCard();
      this.resizeObserverStudentCard = new ResizeObserver(() => {
        this.setSizeStudentCard();
      });
      this.resizeObserverStudentCard.observe(
        this.$refs.studentcardcontainer[0] ?? this.$refs.studentcardcontainer
      );
    },
    getPercentageOfHeight(percentage) {
      return `${((this.studentCardHeight ?? 300) / 100) * (percentage ?? 5)}px`;
    },
    getPositions(key) {
      let data = Object.assign({}, this.currentPosition[key] ?? {});
      Object.keys(data).forEach((k) => {
        data[k] = `${data[k]}%`;
      });
      return data;
    },
  },
};
</script>

<style scoped>
.container__card {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  padding: 12px 24px;
  gap: 8px;
  width: 161px;
}
.btn_sair {
  background: linear-gradient(90deg, #fa9500 0%, #fa6400 100%);
  border: 2px solid #fa9500;
  border-radius: 300px;
}
.btn__action {
  background: linear-gradient(90deg, #ff8008 0%, #ffc837 100%);
  border: 2px solid #fa9500;
  border-radius: 3600px;
  width: max-content !important;
  max-width: unset !important;
  min-width: unset !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.info__carteirinha {
  position: absolute;
}
.img_qrcode {
  position: absolute;
  margin: 190px 0 0 48px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 45px;
  height: 45px;
}
.content__qrcode {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.img_qrcode_full {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex-grow: 1;
}

.txt__card {
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-weight: bold;
}

.txt__card.no__uppercase {
  text-transform: none;
}

.img_section {
  width: 400px;
}
.flag_status {
  margin: 10px 0;
  border-radius: 0.5rem;
  width: 395px;
  height: 50px;
  background: linear-gradient(90deg, #ff7f08e3 0%, #ffc637e5 100%);
  text-align: center;
  position: absolute;
}
.status_card {
  margin: 13px 0 0 0;
  font-family: "Libre Franklin";
  font-style: normal;
  font-size: 21px;
  font-weight: 700;
  color: #ffffff;
}

.card__general {
  width: 100%;
  height: 100%;
}
.card__front,
.card__back {
  display: flex;
  backface-visibility: hidden;
  flex-direction: column;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.card__back {
  display: flex;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 1rem;
}

.carteirinha {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #1c4173;
}
.img_card {
  margin: 0;
}
</style>
