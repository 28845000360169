<template>
  <div
    :style="`background-image: url(./../../imgs/home/bg_section_1.jpg)`"
    class="content__section_1"
  >
    <v-container class="pa-0">
      <HeaderComponent />
      <div class="content__about">
        <v-row class="ma-0">
          <v-col cols="12" sm="12" md="12" lg="6" xl="6">
            <div class="txt_section">
              <h3 class="txt_sobre">Sobre</h3>
              <div class="linha_caminho">
                <router-link href="/" class="link inicial">
                  <p class="inicial">Página inicial</p>
                </router-link>
                <p class="inicial">&nbsp;&nbsp;>>&nbsp;&nbsp;</p>
                <p class="pagina">SOBRE</p>
              </div>
              <p class="paragrafo">
                Somos uma plataforma tecnológica de suporte às entidades que
                desejam inovar com segurança, praticidade e tecnologia na versão
                da Carteira Digital, oferecendo diversas vantagens ao publico
                estudantil, da educação básica ao ensino superior.
              </p>
              <p class="paragrafo">
                É com essa aspiração que queremos estar no smartphone de cada um
                dos estudantes, podendo levar facilidade e praticidade de
                utilização nos estabelecimentos conveniados e em nossa rede de
                parceiros.
              </p>
              <p class="paragrafo">
                Desta forma o Meia Entrada Digital se presta a dar suporte para
                que Entidades Estudantis em sua missão possam com tranquilidade
                e segurança emitir a Carteira Digital. Esperamos você para
                inovar conosco!
              </p>
              <v-btn class="btn_carteirinha" :to="'/'">
                <span class="centrarlizar_btn">
                  <p class="carteirinha">Faça sua carteirinha agora</p>
                </span>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="6" xl="6">
            <v-responsive :aspect-ratio="1">
              <div
                class="content__image"
                :style="`background-image: url(./../../imgs/about/phone_card.png)`"
              ></div>
            </v-responsive>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "SobrePage",
  props: {},
  components: {
    HeaderComponent,
    FooterComponent,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>
.content__section_1 {
  background-size: auto 100%;
  background-position: right center;
}

.content__about {
  backdrop-filter: blur(15px);
  padding: 2rem;
}
.linha_caminho {
  display: flex;
}

.content__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
}

.bg_section {
  border-radius: 1rem;
  position: relative;
  z-index: 1;
  height: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 2rem;
}
.txt_sobre {
  margin-top: 25px;
  font-size: 69px;
  line-height: 84px;
  color: white;
  font-weight: 700;
  font-family: yummo, sans-serif;
}
.inicial {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: white;
}
.pagina {
  font-size: 14px;
  line-height: 17px;
  color: white;
}
.section {
  display: flex;
  justify-content: space-between;
}
.paragrafo {
  font-family: "Calibri", "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: white;
}
.btn_carteirinha {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  margin: 25px 0;
  gap: 8px;
  width: 320px;
  height: 46px;
  background: linear-gradient(90deg, #ff8008 0%, #ffc837 100%);
  border: 2px solid #fa9500;
  border-radius: 300px;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.carteirinha {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #1c4173;
}
.img_card {
  margin: 0;
}
@media screen and (orientation: portrait), (max-width: 1000px) {
  .btn_carteirinha {
    width: 100%;
  }
  .bg_fantasma {
    height: 700px;
  }

  .paragrafo {
    width: 100%;
    text-align: center;
  }
  .btn_carteirinha {
    align-items: center;
    text-align: center;
  }
  .linha_caminho {
    text-align: start;
  }
}
</style>
