<template>
  <div
    class="content__card"
    :style="{
      width: '100%',
    }"
  >
    <v-progress-linear
      indeterminate
      color="black"
      v-if="loading"
    ></v-progress-linear>
    <h3 class="text-center" v-if="loading">Carregando dados</h3>
    <v-alert type="error" prominent v-if="!loading && !data">
      Ocorreu um erro ao carregar a carteirinha. Verifique o link e tente
      novamente mais tarde!,
    </v-alert>
    <CarteirinhaComponent :data="data" v-if="!loading && data" />
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {
    CarteirinhaComponent: () => import("@/components/CarteirinhaComponent.vue"),
  },
  data() {
    return {
      loading: false,
      data: null,
    };
  },
  computed: {
    email() {
      return this.$route?.params?.email;
    },
    partnerToken() {
      return this.$route?.params?.partnerToken;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      if (this.loading) return;
      if (!this.email || !this.partnerToken) return;
      this.loading = true;
      await axios
        .get(`${this.$http.defaults.baseURL}/automatic-partner/estudents`, {
          params: {
            email: this.email,
            fpwa: 1, // more info on backend
          },
          headers: {
            Authorization: `Bearer ${this.partnerToken}`,
          },
        })
        .then((r) => {
          this.data = r?.data?.estudent;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.response || e);
          this.loading = false;
          this.$notify({
            group: "app",
            type: "error",
            title: "Ocorreu um erro",
            text: e.response?.data?.message || "Tente novamente mais tarde",
          });
        });
    },
  },
};
</script>

<style>
</style>