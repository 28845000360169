<template>
  <div style="">
    <v-form @submit.prevent="submit">
      <v-row class="section_document">
        <v-row class="ma-0 mt-3" align="center" justify="center">
          <v-btn
            width="150"
            height="150"
            dark
            elevation="0"
            @click="
              $refs.fileupload.value = null;
              $refs.fileupload.click();
            "
          >
            <v-avatar
              color="#043980"
              class="btn_img"
              rounded
              width="150"
              height="150"
            >
              <p class="txt_label">Documento (frente)</p>
              <v-icon v-if="!img" dark size="75px"> mdi-file-document </v-icon>
              <v-img
                v-else
                class="img_view"
                :src="img"
                :lazy-src="img"
                width="90%"
                height="90%"
              ></v-img>

              <div
                v-if="!img"
                class="img_cadastrada"
                :style="`background-image: url(${user.files[1].url || ''});`"
              ></div>

              <input
                type="file"
                name="fileupload"
                id="fileupload"
                ref="fileupload"
                accept="image/*"
                @change="setImage"
              />
              <v-btn v-if="!img" class="btn__upload" icon
                ><v-icon>mdi-cloud-upload-outline</v-icon></v-btn
              >
            </v-avatar>
          </v-btn>
        </v-row>
        <v-row class="ma-0 mt-3" align="center" justify="center">
          <v-btn
            width="150"
            height="150"
            dark
            elevation="0"
            @click="
              $refs.fileupload_verso.value = null;
              $refs.fileupload_verso.click();
            "
          >
            <v-avatar
              color="#043980"
              class="btn_img"
              rounded
              width="150"
              height="150"
            >
              <p class="txt_label">Documento (verso)</p>
              <v-icon v-if="!img_verso" dark size="75px">
                mdi-file-document
              </v-icon>
              <v-img
                v-else
                class="img_view"
                :src="img_verso"
                :lazy-src="img_verso"
                width="90%"
                height="90%"
              ></v-img>

              <div
                v-if="!img_verso"
                class="img_cadastrada"
                :style="`background-image: url(${user.files[2].url || ''});`"
              ></div>

              <input
                type="file"
                name="fileupload_verso"
                id="fileupload_verso"
                ref="fileupload_verso"
                accept="image/*"
                @change="setImage_verso"
              />
              <v-btn v-if="!img_verso" class="btn__upload" icon
                ><v-icon>mdi-cloud-upload-outline</v-icon></v-btn
              >
            </v-avatar>
          </v-btn>
        </v-row>
        <v-row class="ma-0 mt-3" align="center" justify="center">
          <v-btn
            width="150"
            height="150"
            dark
            elevation="0"
            @click="
              $refs.fileupload_certificate.value = null;
              $refs.fileupload_certificate.click();
            "
          >
            <v-avatar
              color="#043980"
              class="btn_img"
              rounded
              width="150"
              height="150"
            >
              <p class="txt_label">Certificado</p>
              <v-icon v-if="!img_certificate" dark size="75px">
                mdi-file-document
              </v-icon>
              <v-img
                v-else
                class="img_view"
                :src="img_certificate"
                :lazy-src="img_certificate"
                width="90%"
                height="90%"
              ></v-img>

              <div
                v-if="!img_certificate"
                class="img_cadastrada"
                :style="`background-image: url(${user.files[4].url || ''});`"
              ></div>

              <input
                type="file"
                name="fileupload_certificate"
                id="fileupload_certificate"
                ref="fileupload_certificate"
                accept="image/*"
                @change="setImage_certificate"
              />
              <v-btn v-if="!img_certificate" class="btn__upload" icon
                ><v-icon>mdi-cloud-upload-outline</v-icon></v-btn
              >
            </v-avatar>
          </v-btn>
        </v-row>
        <v-row class="ma-0 mt-3" align="center" justify="center">
          <v-btn
            width="150"
            height="150"
            dark
            elevation="0"
            @click="
              $refs.fileupload_selfie.value = null;
              $refs.fileupload_selfie.click();
            "
          >
            <v-avatar
              color="#043980"
              class="btn_img"
              rounded
              width="150"
              height="150"
            >
              <p class="txt_label">Selfie</p>
              <v-icon v-if="!img_selfie" dark size="75px">
                mdi-file-document
              </v-icon>
              <v-img
                v-else
                class="img_view"
                :src="img_selfie"
                :lazy-src="img_selfie"
                width="90%"
                height="90%"
              ></v-img>

              <div
                v-if="!img_selfie"
                class="img_cadastrada"
                :style="`background-image: url(${user.files[0].url || ''});`"
              ></div>

              <input
                type="file"
                name="fileupload_selfie"
                id="fileupload_selfie"
                ref="fileupload_selfie"
                accept="image/*"
                @change="setImage_selfie"
              />
              <v-btn v-if="!img_selfie" class="btn__upload" icon
                ><v-icon>mdi-cloud-upload-outline</v-icon></v-btn
              >
            </v-avatar>
          </v-btn>
        </v-row>
      </v-row>
      <br /><br />
      <v-btn color="primary" class="mr-4" type="submit" :disabled="loading"
        >Avançar</v-btn
      >
    </v-form>
    <v-dialog v-model="showDialog" width="90vw" persistent>
      <v-card max-height="90vh" dark>
        <v-card-title>
          Enquadre o sua foto. Utilize os controles abaixo.
        </v-card-title>
        <vue-cropper
          :containerStyle="containerStyle"
          ref="cropper"
          :aspect-ratio="1 / 1"
          :src="inputFileImage"
          preview=".preview"
          :viewMode="1"
          :minContainerHeight="getCropperHeight()"
          :minCanvasHeight="getCropperHeight()"
        />
        <v-card-text>
          <v-row class="ma-0" align="center" justify="center">
            <v-btn @click.prevent="zoom(0.2)" icon>
              <v-icon>mdi-magnify-plus-outline</v-icon>
            </v-btn>
            <v-btn @click.prevent="zoom(-0.2)" icon>
              <v-icon>mdi-magnify-minus-outline</v-icon>
            </v-btn>
            <v-btn @click.prevent="move(-10, 0)" icon>
              <v-icon>mdi-arrow-collapse-left</v-icon>
            </v-btn>
            <v-btn @click.prevent="move(10, 0)" icon>
              <v-icon>mdi-arrow-collapse-right</v-icon>
            </v-btn>
            <v-btn @click.prevent="move(0, -10)" icon>
              <v-icon>mdi-arrow-collapse-up</v-icon>
            </v-btn>
            <v-btn @click.prevent="move(0, 10)" icon>
              <v-icon>mdi-arrow-collapse-down</v-icon>
            </v-btn>
            <v-btn @click.prevent="rotate(90)" icon>
              <v-icon>mdi-rotate-right</v-icon>
            </v-btn>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" @click="showDialog = !showDialog" dark
            >Descartar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn @click="cropImage" color="green" dark :loading="loading"
            >Salvar arquivo</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDialog_verso" width="90vw" persistent>
      <v-card max-height="90vh" dark>
        <v-card-title>
          Enquadre o sua foto. Utilize os controles abaixo.
        </v-card-title>
        <vue-cropper
          :containerStyle="containerStyle"
          ref="cropper"
          :aspect-ratio="1 / 1"
          :src="inputFileImage"
          preview=".preview"
          :viewMode="1"
          :minContainerHeight="getCropperHeight()"
          :minCanvasHeight="getCropperHeight()"
        />
        <v-card-text>
          <v-row class="ma-0" align="center" justify="center">
            <v-btn @click.prevent="zoom(0.2)" icon>
              <v-icon>mdi-magnify-plus-outline</v-icon>
            </v-btn>
            <v-btn @click.prevent="zoom(-0.2)" icon>
              <v-icon>mdi-magnify-minus-outline</v-icon>
            </v-btn>
            <v-btn @click.prevent="move(-10, 0)" icon>
              <v-icon>mdi-arrow-collapse-left</v-icon>
            </v-btn>
            <v-btn @click.prevent="move(10, 0)" icon>
              <v-icon>mdi-arrow-collapse-right</v-icon>
            </v-btn>
            <v-btn @click.prevent="move(0, -10)" icon>
              <v-icon>mdi-arrow-collapse-up</v-icon>
            </v-btn>
            <v-btn @click.prevent="move(0, 10)" icon>
              <v-icon>mdi-arrow-collapse-down</v-icon>
            </v-btn>
            <v-btn @click.prevent="rotate(90)" icon>
              <v-icon>mdi-rotate-right</v-icon>
            </v-btn>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" @click="showDialog_verso = !showDialog_verso" dark
            >Descartar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn @click="cropImage_verso" color="green" dark :loading="loading"
            >Salvar arquivo</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDialog_certificate" width="90vw" persistent>
      <v-card max-height="90vh" dark>
        <v-card-title>
          Enquadre o sua foto. Utilize os controles abaixo.
        </v-card-title>
        <vue-cropper
          :containerStyle="containerStyle"
          ref="cropper"
          :aspect-ratio="1 / 1"
          :src="inputFileImage"
          preview=".preview"
          :viewMode="1"
          :minContainerHeight="getCropperHeight()"
          :minCanvasHeight="getCropperHeight()"
        />
        <v-card-text>
          <v-row class="ma-0" align="center" justify="center">
            <v-btn @click.prevent="zoom(0.2)" icon>
              <v-icon>mdi-magnify-plus-outline</v-icon>
            </v-btn>
            <v-btn @click.prevent="zoom(-0.2)" icon>
              <v-icon>mdi-magnify-minus-outline</v-icon>
            </v-btn>
            <v-btn @click.prevent="move(-10, 0)" icon>
              <v-icon>mdi-arrow-collapse-left</v-icon>
            </v-btn>
            <v-btn @click.prevent="move(10, 0)" icon>
              <v-icon>mdi-arrow-collapse-right</v-icon>
            </v-btn>
            <v-btn @click.prevent="move(0, -10)" icon>
              <v-icon>mdi-arrow-collapse-up</v-icon>
            </v-btn>
            <v-btn @click.prevent="move(0, 10)" icon>
              <v-icon>mdi-arrow-collapse-down</v-icon>
            </v-btn>
            <v-btn @click.prevent="rotate(90)" icon>
              <v-icon>mdi-rotate-right</v-icon>
            </v-btn>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red"
            @click="showDialog_certificate = !showDialog_certificate"
            dark
            >Descartar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            @click="cropImage_certificate"
            color="green"
            dark
            :loading="loading"
            >Salvar arquivo</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDialog_selfie" width="90vw" persistent>
      <v-card max-height="90vh" dark>
        <v-card-title>
          Enquadre o sua foto. Utilize os controles abaixo.
        </v-card-title>
        <vue-cropper
          :containerStyle="containerStyle"
          ref="cropper"
          :aspect-ratio="1 / 1"
          :src="inputFileImage"
          preview=".preview"
          :viewMode="1"
          :minContainerHeight="getCropperHeight()"
          :minCanvasHeight="getCropperHeight()"
        />
        <v-card-text>
          <v-row class="ma-0" align="center" justify="center">
            <v-btn @click.prevent="zoom(0.2)" icon>
              <v-icon>mdi-magnify-plus-outline</v-icon>
            </v-btn>
            <v-btn @click.prevent="zoom(-0.2)" icon>
              <v-icon>mdi-magnify-minus-outline</v-icon>
            </v-btn>
            <v-btn @click.prevent="move(-10, 0)" icon>
              <v-icon>mdi-arrow-collapse-left</v-icon>
            </v-btn>
            <v-btn @click.prevent="move(10, 0)" icon>
              <v-icon>mdi-arrow-collapse-right</v-icon>
            </v-btn>
            <v-btn @click.prevent="move(0, -10)" icon>
              <v-icon>mdi-arrow-collapse-up</v-icon>
            </v-btn>
            <v-btn @click.prevent="move(0, 10)" icon>
              <v-icon>mdi-arrow-collapse-down</v-icon>
            </v-btn>
            <v-btn @click.prevent="rotate(90)" icon>
              <v-icon>mdi-rotate-right</v-icon>
            </v-btn>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red"
            @click="showDialog_selfie = !showDialog_selfie"
            dark
            >Descartar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn @click="cropImage_selfie" color="green" dark :loading="loading"
            >Salvar arquivo</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
export default {
  props: ['active', 'userData', 'userFaceData'],

  name: 'UploadComponent',
  components: {
    VueCropper,
  },
  data() {
    return {
      img: null,
      img_verso: null,
      img_certificate: null,
      img_selfie: null,
      inputFileImage: null,
      fileResult: null,
      showDialog: false,
      showDialog_verso: false,
      showDialog_certificate: false,
      showDialog_selfie: false,
      containerStyle: {
        minheight: 0,
        height: '50vh',
      },
      form: {
        file_document: '',
        file_document_back: '',
        file_certificate: '',
        file_selfie: '',
      },
      loading: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  mounted() {
    console.log('Dados do estudante', this.$store.state.user);
    setTimeout(() => {
      this.$set(this.form, 'file_document', this.user?.file_document);
      this.$set(this.form, 'file_document_back', this.user?.file_document_back);
      this.$set(this.form, 'file_certificate', this.user?.file_certificate);
      this.$set(this.form, 'file_selfie', this.user?.file_selfie);
    }, 500);
  },
  watch: {
    showDialog(v) {
      if (!v && this.img) {
        let timestamp = new Date().getTime();
        this.form.file_document = this.dataURLtoFile(
          this.img,
          `image${timestamp}.png`
        );
      }
    },
    showDialog_verso(v) {
      if (!v && this.img_verso) {
        let timestamp = new Date().getTime();

        this.form.file_document_back = this.dataURLtoFile(
          this.img_verso,
          `image${timestamp}.png`
        );
      }
    },
    showDialog_certificate(v) {
      if (!v && this.img_certificate) {
        let timestamp = new Date().getTime();

        this.form.file_certificate = this.dataURLtoFile(
          this.img_certificate,
          `image${timestamp}.png`
        );
      }
    },
    showDialog_selfie(v) {
      if (!v && this.img_selfie) {
        let timestamp = new Date().getTime();

        this.form.file_selfie = this.dataURLtoFile(
          this.img_selfie,
          `image${timestamp}.png`
        );
      }
    },
  },
  methods: {
    async submit() {
      this.loading = true;
      console.log('enviando form pro server', this.form);

      try {
        let dataForm = new FormData();
        for (let key in this.form) {
          dataForm.append(key, this.form[key]);
        }

        console.log('dataForm', dataForm);

        const response = await this.$http.post(
          `/estudents/${this.$store.state.user.id}/documents`,
          dataForm,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem('tk')}`,
            },
          }
        );

        this.loading = false;
        if (response.data.message == 'Documentos atualizados com sucesso') {
          this.$store.commit('setUser', {
            ...this.$store.state.user,
            ...response.data.estudent,
          });

          this.$notify({
            group: 'app',
            type: 'success',
            title: 'Seu cadastro foi realizado',
            text: 'Com sucesso!',
          });
          await this.updateStatus();
          this.$router.push({ name: 'Dashboard' });
        }
      } catch (e) {
        this.loading = false;
        console.error(e);
      }
    },
    async updateStatus() {
      try {
        const payload = {
          status: 1,
          card_estudent_id: this.$store.state.user.card_active.id,
        };
        console.log('payload', payload);
        const response = await this.$http.put(`/estudents/status`, payload, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('tk')}`,
          },
        });

        console.log('RETORNO', response);
        console.log('Response', response.data.success);

        if (response.data.success) {
          console.log('user Card', this.$store.state.user.card_active);
          let user = this.$store.state.user.card_active;
          user.card_active.status = 1;
          this.$store.commit('setUser', user);
        }
      } catch (e) {
        console.error('ERRO DE ATUALIZACAO DE STATUS', e);
      }
    },
    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf('image/') === -1) {
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.inputFileImage = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
        this.showDialog = true;
      } else {
        alert(
          'Seu navegador não é compatível com o Crop de Imagem. Tente novamente com Firefox, Chrome, Edge ou Safari.'
        );
      }
    },
    setImage_verso(e) {
      const file = e.target.files[0];
      if (file.type.indexOf('image/') === -1) {
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.inputFileImage = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
        this.showDialog_verso = true;
      } else {
        alert(
          'Seu navegador não é compatível com o Crop de Imagem. Tente novamente com Firefox, Chrome, Edge ou Safari.'
        );
      }
    },
    setImage_certificate(e) {
      const file = e.target.files[0];
      if (file.type.indexOf('image/') === -1) {
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.inputFileImage = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
        this.showDialog_certificate = true;
      } else {
        alert(
          'Seu navegador não é compatível com o Crop de Imagem. Tente novamente com Firefox, Chrome, Edge ou Safari.'
        );
      }
    },
    setImage_selfie(e) {
      const file = e.target.files[0];
      if (file.type.indexOf('image/') === -1) {
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.inputFileImage = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
        this.showDialog_selfie = true;
      } else {
        alert(
          'Seu navegador não é compatível com o Crop de Imagem. Tente novamente com Firefox, Chrome, Edge ou Safari.'
        );
      }
    },
    getCropperHeight() {
      return window.innerHeight * 0.6;
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
    cropImage() {
      this.img = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.showDialog = false;
    },
    cropImage_verso() {
      this.img_verso = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.showDialog_verso = false;
    },
    cropImage_certificate() {
      this.img_certificate = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.showDialog_certificate = false;
    },
    cropImage_selfie() {
      this.img_selfie = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.showDialog_selfie = false;
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
  },
};
</script>

<style>
#fileupload {
  display: none;
}
.img_cadastrada {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100px;
  height: 90px;
}
.img_view {
  padding: 0 15px;
}
.txt_label {
  padding-top: 15px;
  font-size: 12px;
}
.btn_img {
  display: flex;
  flex-direction: column;
}
.section_document {
  display: flex;
  justify-content: space-between;
}
@media screen and (orientation: portrait), (max-width: 1000px) {
  .section_document {
    display: flex;
    flex-direction: column;
  }
}
</style>
