import { render, staticRenderFns } from "./sobre.vue?vue&type=template&id=ed6c9a86&scoped=true&"
import script from "./sobre.vue?vue&type=script&lang=js&"
export * from "./sobre.vue?vue&type=script&lang=js&"
import style0 from "./sobre.vue?vue&type=style&index=0&id=ed6c9a86&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed6c9a86",
  null
  
)

export default component.exports